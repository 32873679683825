import { DatePicker, LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
} from '@mui/material';
import {
	ApiOccupancy,
	ApiTenant,
	CreateOccupancyParamsData,
	OccupancyProperty,
	UpdateOccupancyParamsData,
} from '@rentcheck/types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DialogTitle } from 'components';
import ResponsiveForm from 'components/responsive-form';
import { SnackbarActions, TenantsActions } from 'store/actions';
import { Dispatch } from 'types';

import FormTextField from 'components/form-text-field';
import moment from 'moment';
import LeaseStatus from './lease-status';
import UnitSelection from './unit-selection';

interface Props {
	resident: ApiTenant;
	occupancy?: ApiOccupancy;
	onClose: () => void;
}

type YesNoT = 'Yes' | 'No';

const OccupancyCreate = ({ occupancy, resident, onClose }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [unit, setUnit] = useState<OccupancyProperty>();
	const [leaseStatus, setLeaseStatus] = useState<ApiOccupancy['status']>();
	const [leaseStart, setLeaseStart] = useState<Date | null>(null);
	const [leaseEnd, setLeaseEnd] = useState<Date | null>(null);
	const [moveIn, setMoveIn] = useState<Date | null>(null);
	const [moveOut, setMoveOut] = useState<Date | null>(null);
	const [rent, setRent] = useState<string>('');
	const [deposit, setDeposit] = useState<string>('');
	const [noticeGiven, setNoticeGiven] = useState<YesNoT>('No');
	const [eviction, setEviction] = useState<YesNoT>('No');

	const [errors, setErrors] = useState<string[]>([]);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (occupancy) {
			setUnit(occupancy.property);
		}

		setLeaseStatus(occupancy?.status);
		setRent(occupancy?.rent?.toString() ?? '');
		setDeposit(occupancy?.security_deposit?.toString() ?? '');
		setNoticeGiven(occupancy?.tags.includes('notice') ? 'Yes' : 'No');
		setEviction(occupancy?.tags.includes('evict') ? 'Yes' : 'No');

		setLeaseStart(
			occupancy?.lease_start ? moment(occupancy.lease_start).toDate() : null
		);
		setLeaseEnd(
			occupancy?.lease_end ? moment(occupancy.lease_end).toDate() : null
		);
		setMoveIn(
			occupancy?.move_in_date ? moment(occupancy.move_in_date).toDate() : null
		);
		setMoveOut(
			occupancy?.move_out_date ? moment(occupancy.move_out_date).toDate() : null
		);
	}, [occupancy]);

	const handleClose = () => {
		onClose();
	};

	const validate = () => {
		const errors: string[] = [];

		if (!unit) {
			errors.push('unit');
		}

		if (!leaseStatus) {
			errors.push('lease_status');
		}

		if (moveIn && moveOut) {
			const moveInMillis = moveIn.getTime();
			const moveOutMillis = moveOut.getTime();

			if (moveInMillis > moveOutMillis) {
				errors.push('move_in');
				errors.push('move_out');

				dispatch(
					SnackbarActions.showError(
						"Move-in date can't happen after Move-out date "
					)
				);
			}
		}

		if (moveIn && leaseStatus) {
			const moveInMillis = moveIn.getTime();
			const nowMillis = new Date().getTime();

			if (leaseStatus === 'current' && moveInMillis > nowMillis) {
				errors.push('move_in');

				dispatch(
					SnackbarActions.showError(
						"Move-in date can't happen in the future if lease is current"
					)
				);
			}

			if (leaseStatus === 'past' && moveInMillis > nowMillis) {
				errors.push('move_in');

				dispatch(
					SnackbarActions.showError(
						"Move-in date can't happen in the future if lease is past"
					)
				);
			}

			if (leaseStatus === 'future' && moveInMillis < nowMillis) {
				errors.push('move_in');

				dispatch(
					SnackbarActions.showError(
						"Move-in date can't happen in the past if lease is future"
					)
				);
			}
		}

		if (moveOut && leaseStatus) {
			const moveOutMillis = moveOut.getTime();
			const nowMillis = new Date().getTime();

			if (leaseStatus === 'past' && moveOutMillis > nowMillis) {
				errors.push('lease_status');
				errors.push('move_out');

				dispatch(
					SnackbarActions.showError(
						"Move-out date can't happen in the future if lease is past"
					)
				);
			}

			if (leaseStatus === 'current' && moveOutMillis < nowMillis) {
				errors.push('lease_status');
				errors.push('move_out');

				dispatch(
					SnackbarActions.showError(
						"Move-out date can't happen in the past if lease is current"
					)
				);
			}

			if (leaseStatus === 'future' && moveOutMillis < nowMillis) {
				errors.push('lease_status');
				errors.push('move_out');

				dispatch(
					SnackbarActions.showError(
						"Move-out date can't happen in the past if lease is future"
					)
				);
			}
		}

		setErrors(errors);

		return errors.length === 0;
	};

	const parseDate = (date: Date | null) =>
		date ? moment(date).format('YYYY-MM-DD') : null;

	const handleCreate = async () => {
		if (!unit) {
			return;
		}

		const payload: CreateOccupancyParamsData = {
			status: leaseStatus as ApiOccupancy['status'],
			property_id: unit.id,
			tags: [],
			lease_start: parseDate(leaseStart),
			lease_end: parseDate(leaseEnd),
			move_in_date: parseDate(moveIn),
			move_out_date: parseDate(moveOut),
			rent: rent ? parseFloat(rent) : null,
			security_deposit: deposit ? parseFloat(deposit) : null,
		};

		if (noticeGiven === 'Yes') {
			payload.tags!.push('notice');
		}

		if (eviction === 'Yes') {
			payload.tags!.push('evict');
		}

		dispatch(TenantsActions.createOccupancy(resident.id, payload))
			.then(() => {
				dispatch(SnackbarActions.showSuccess('Lease Added'));
				handleClose();
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const handleUpdate = () => {
		if (!occupancy) return;

		if (!unit) return;

		const payload: UpdateOccupancyParamsData = {
			status: leaseStatus as ApiOccupancy['status'],
			property_id: unit.id,
			tags: [],
			lease_start: parseDate(leaseStart),
			lease_end: parseDate(leaseEnd),
			move_in_date: parseDate(moveIn),
			move_out_date: parseDate(moveOut),
			rent: rent ? parseFloat(rent) : null,
			security_deposit: deposit ? parseFloat(deposit) : null,
		};

		if (noticeGiven === 'Yes') {
			payload.tags?.push('notice');
		}

		if (eviction === 'Yes') {
			payload.tags?.push('evict');
		}

		dispatch(
			TenantsActions.updateOccupancy(occupancy.tenant_id, occupancy.id, payload)
		)
			.then(() => {
				dispatch(SnackbarActions.showSuccess('Lease Updated'));
				handleClose();
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const handleSave = () => {
		if (!validate()) {
			return;
		}

		setLoading(true);

		if (!occupancy) {
			return handleCreate();
		}

		handleUpdate();
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		setValue: (v: string) => void
	) => {
		const regex = /^\d+\.?\d*$/gm;
		if (e.target.value === '' || regex.test(e.target.value)) {
			setValue(e.target.value);
		}
	};

	if (occupancy && !leaseStatus) {
		return null;
	}

	return (
		<Dialog open onClose={handleClose}>
			<DialogTitle
				title={occupancy ? 'Edit Lease' : 'Add Lease'}
				icon={solid('user-circle')}
				onClose={handleClose}
			/>

			<DialogContent>
				<ResponsiveForm onSubmit={handleSave} marginBottom={0}>
					<UnitSelection unit={unit} setUnit={setUnit} errors={errors} />
					<LeaseStatus
						leaseStatus={leaseStatus}
						setLeaseStatus={setLeaseStatus}
						errors={errors}
					/>

					<DatePicker
						inputFormat="MM/dd/yy"
						onChange={(e: any) => setLeaseStart(e)}
						value={leaseStart}
						renderInput={(props) => (
							<FormTextField
								{...props}
								fullWidth
								title="Lease Start"
								ref={undefined}
							/>
						)}
					/>
					<DatePicker
						inputFormat="MM/dd/yy"
						onChange={(e: any) => setLeaseEnd(e)}
						value={leaseEnd}
						renderInput={(props) => (
							<FormTextField
								{...props}
								fullWidth
								title="Lease End"
								ref={undefined}
							/>
						)}
					/>

					<DatePicker
						inputFormat="MM/dd/yy"
						onChange={(e: any) => setMoveIn(e)}
						value={moveIn}
						renderInput={(props) => (
							<FormTextField
								{...props}
								fullWidth
								title="Move-in Date"
								error={errors.includes('move_in')}
								ref={undefined}
							/>
						)}
					/>
					<DatePicker
						inputFormat="MM/dd/yy"
						onChange={(e: any) => setMoveOut(e)}
						value={moveOut}
						renderInput={(props) => (
							<FormTextField
								{...props}
								fullWidth
								title="Move-out Date"
								error={errors.includes('move_out')}
								ref={undefined}
							/>
						)}
					/>

					<FormTextField
						fullWidth
						title="Monthly Rent"
						placeholder="Monthly Rent"
						value={rent}
						onChange={(e) => handleChange(e, setRent)}
						icon={regular('dollar-sign')}
					/>
					<FormTextField
						fullWidth
						title="Security Deposit"
						placeholder="Security Deposit"
						value={deposit}
						onChange={(e) => handleChange(e, setDeposit)}
						icon={regular('dollar-sign')}
					/>

					<Autocomplete
						fullWidth
						disablePortal
						disableClearable
						options={['Yes', 'No'] as YesNoT[]}
						value={noticeGiven}
						onChange={(_, newValue) => newValue && setNoticeGiven(newValue)}
						renderInput={(params) => (
							<FormTextField
								{...params}
								title="Notice Given"
								placeholder="Notice Given"
								icon={regular('flag-checkered')}
							/>
						)}
					/>
					<Autocomplete
						fullWidth
						disablePortal
						disableClearable
						options={['Yes', 'No'] as YesNoT[]}
						value={eviction}
						onChange={(_, newValue) => newValue && setEviction(newValue)}
						renderInput={(params) => (
							<FormTextField
								{...params}
								title="Eviction"
								placeholder="Eviction"
								icon={regular('square-exclamation')}
							/>
						)}
					/>
				</ResponsiveForm>
			</DialogContent>

			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleClose}>
					Cancel
				</Button>

				<LoadingButton
					id="button-confirm"
					variant="contained"
					loading={loading}
					onClick={handleSave}>
					Save
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default OccupancyCreate;
