import { useState, useMemo } from 'react';
import {
	FormControl,
	Select,
	MenuItem,
	FormHelperText,
	SelectProps,
	SelectChangeEvent,
} from '@mui/material';

interface Props extends Omit<SelectProps, 'onChange'> {
	options: {
		value: string;
		label: string;
	}[];
	onChange: (event: SelectChangeEvent) => void;
}

const SelectWithHelperText = ({
	options,
	onChange,
	value,
	...props
}: Props) => {
	const [touched, setTouched] = useState(false);

	const handleOnBlur = () => {
		setTouched(true);
	};

	const helperText = useMemo(() => {
		if (!value) return 'Required';
		return '';
	}, [value]);

	const error = useMemo(() => {
		if (!value && touched) return true;

		return false;
	}, [value, touched]);

	return (
		<FormControl fullWidth error={error}>
			<Select
				{...props}
				onBlur={handleOnBlur}
				value={value}
				onChange={onChange as any}>
				{options.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>

			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default SelectWithHelperText;
