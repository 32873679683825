"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentvinePropertyTypes = void 0;
exports.RentvinePropertyTypes = [
    'Single Family Home',
    'Apartment',
    'Condo',
    'Townhouse',
    'Duplex',
    'Multiplex',
    'Loft',
    'MobileHome',
    'Commercial',
    'Garage',
];
