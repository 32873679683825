import {
	useState,
	useRef,
	useCallback,
	useMemo,
	MouseEventHandler,
} from 'react';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Typography,
	TextField,
	InputAdornment,
	Popover,
	Divider,
	Autocomplete,
	MenuItem,
	FilterOptionsState,
} from '@mui/material';

import { ApiTeam } from '@rentcheck/types';
import { colors } from '@rentcheck/theme';

import { Column, Button, SpacedRow, Tags, OptionMenuItem } from 'components';
import IconButton from 'components/icon-button';

export type SelectedCondition = 'any_of' | 'none_of';

interface Props {
	apiTeams: ApiTeam[];
	teamIdFilters: string[];
	setTeamIdFilters: (teamIdFilters: string[]) => void;
	search: string;
	setSearch: (search: string) => void;
}

const SelectProperties = ({
	apiTeams,
	teamIdFilters,
	setTeamIdFilters,
	search,
	setSearch,
}: Props) => {
	const ref = useRef<HTMLDivElement>(null);

	const [tempSelectedCondition, setTempSelectedCondition] =
		useState<SelectedCondition>('any_of');
	const [tempSelectedTeams, setTempSelectedTeams] = useState<ApiTeam[]>([]);

	const [selectedCondition, setSelectedCondition] =
		useState<SelectedCondition>('any_of');
	const [selectedTeams, setSelectedTeams] = useState<ApiTeam[]>([]);

	const [menuAnchor, setMenuAnchor] = useState<HTMLDivElement | null>(null);
	const [touched, setTouched] = useState(false);

	const handleBlur = () => {
		setTouched(true);
	};

	const handleShowFilter: MouseEventHandler<HTMLDivElement> = (e) => {
		setTempSelectedCondition(selectedCondition);
		setTempSelectedTeams(selectedTeams);
		setMenuAnchor(e.currentTarget);
	};

	const handleClosePopover = () => {
		setTempSelectedCondition(selectedCondition);
		setTempSelectedTeams(selectedTeams);
		setMenuAnchor(null);
	};

	const onApply = () => {
		setSelectedCondition(tempSelectedCondition);
		setSelectedTeams(tempSelectedTeams);

		const selectedTeamsIds = tempSelectedTeams.map((t) => t.id);

		if (tempSelectedCondition === 'any_of') {
			setTeamIdFilters(selectedTeamsIds);
		}

		if (tempSelectedCondition === 'none_of') {
			setTeamIdFilters(
				apiTeams
					.filter((at) => !selectedTeamsIds.includes(at.id))
					.map((t) => t.id)
			);
		}

		handleClosePopover();
	};

	const filterOptions = useCallback(
		(options: ApiTeam[], state: FilterOptionsState<ApiTeam>) => {
			const searchTerm = state.inputValue.toLowerCase();

			return options.filter((option) =>
				option.internal_label.toLowerCase().includes(searchTerm)
			);
		},
		[]
	);

	const memoizedApiTeams = useMemo(() => apiTeams, [apiTeams]);
	const showError = !tempSelectedTeams.length && touched;

	return (
		<>
			<TextField
				style={{ paddingTop: 10 }}
				fullWidth
				placeholder="Search"
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<FontAwesomeIcon icon={regular('search')} />
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment
							position="start"
							sx={{ cursor: 'pointer' }}
							ref={ref}
							onClick={handleShowFilter}>
							<IconButton
								icon={solid('sliders')}
								buttonSx={{
									color: teamIdFilters.length ? colors.white : '',
									backgroundColor: teamIdFilters.length
										? colors.primary.default
										: colors.white,
									':hover': {
										backgroundColor: teamIdFilters.length
											? colors.primary[300]
											: '#CCCCCCBD',
									},
								}}
							/>
						</InputAdornment>
					),
				}}
			/>

			<Popover
				sx={{ marginTop: 1 }}
				open={!!menuAnchor}
				anchorEl={menuAnchor}
				onClose={handleClosePopover}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
				<Column style={{ padding: 8 }}>
					<Typography variant="overline" ml={1} color="#445567">
						Team
					</Typography>

					<Column style={{ padding: 8 }}>
						<TextField
							select
							sx={{ mb: 2 }}
							value={tempSelectedCondition}
							onChange={(e) =>
								setTempSelectedCondition(e.target.value as SelectedCondition)
							}>
							<MenuItem value="any_of">Is any of these</MenuItem>
							<MenuItem value="none_of">Is none of these</MenuItem>
						</TextField>

						<Autocomplete
							openOnFocus
							clearIcon={null}
							sx={{ width: 300, mb: 2 }}
							ListboxProps={{ style: { maxHeight: 200 } }}
							multiple={true}
							options={memoizedApiTeams}
							filterOptions={filterOptions}
							disableCloseOnSelect={true}
							value={tempSelectedTeams}
							onChange={(e, value) => setTempSelectedTeams(value as any)}
							renderTags={(value, getTagProps) => (
								<Tags
									value={value}
									getTagProps={getTagProps}
									getOptionLabel={(option) => option.internal_label}
								/>
							)}
							renderOption={(props, option) => (
								<OptionMenuItem
									option={option}
									autocompleteProps={props}
									getOptionLabel={(option) => option.internal_label}
								/>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									helperText={showError ? 'Required' : ''}
									error={showError}
									sx={{ '.MuiInputBase-root': { minHeight: 56, padding: 5 } }}
									placeholder="Enter or select team"
									onBlur={handleBlur}
								/>
							)}
						/>
					</Column>

					<Divider sx={{ mb: 1 }} />

					<SpacedRow>
						<div />
						<Button
							onClick={onApply}
							size="small"
							sx={{ borderRadius: 1 }}
							disabled={!tempSelectedCondition || !tempSelectedTeams.length}>
							Apply
						</Button>
					</SpacedRow>
				</Column>
			</Popover>
		</>
	);
};

export default SelectProperties;
