import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Feature } from '@rentcheck/types';

import { Column, Row } from 'components';
import FormTextField from 'components/form-text-field';
import IconButton from 'components/icon-button';
import Rating from 'screens/inspections/details/content/completed/list/row/rating';
import { FeatureActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';
import { featureDisplayName } from 'utils/helpers';
import { useIsMobileBreakpoint } from 'utils/hooks';

interface Props {
	feature: Feature;
	setRevisionNotes: (notes: { [key: string]: string }) => void;
	revisionNotes: { [key: string]: string };
}

export default ({ feature, setRevisionNotes, revisionNotes }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const isMobile = useIsMobileBreakpoint();

	const [loading, setLoading] = useState(false);
	const [showTextField, setShowTextField] = useState(false);
	const [value, setValue] = useState('');

	useEffect(() => {
		if (!feature.revision_notes) return;

		setValue(feature.revision_notes);
		setShowTextField(true);
	}, []);

	useEffect(() => {
		setRevisionNotes({ ...revisionNotes, [feature.id]: value });
	}, [value]);

	const handleRemoveFromRevision = () => {
		setLoading(true);
		setRevisionNotes({ ...revisionNotes, [feature.id]: '' });

		dispatch(
			FeatureActions.updateFeature(feature.id, {
				revision_requested: false,
				revision_notes: '',
			})
		)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	if (isMobile) {
		return (
			<>
				<TableRow>
					<TableCell>
						<Column>
							<Typography mb={1}>{featureDisplayName(feature)}</Typography>
							{!!feature.notes && (
								<Typography
									color="#4D4D4D"
									mb={1}
									sx={{ wordBreak: 'break-word' }}>
									{feature.notes}
								</Typography>
							)}
							{isMobile && <Rating feature={feature} />}
						</Column>
					</TableCell>
					{!isMobile && (
						<TableCell>
							<Rating feature={feature} />
						</TableCell>
					)}
					<TableCell>
						<Row>
							<Tooltip title={showTextField ? '' : 'Add Revision Note'}>
								<span>
									<IconButton
										icon={regular('message-lines')}
										disabled={!!value}
										onClick={() => setShowTextField(!showTextField)}
										buttonSx={{
											backgroundColor: showTextField ? '#D9D9D9' : '',
										}}
									/>
								</span>
							</Tooltip>
							<IconButton
								loading={loading}
								icon={regular('times')}
								onClick={handleRemoveFromRevision}
							/>
						</Row>
					</TableCell>
				</TableRow>

				{showTextField && (
					<TableRow>
						<TableCell colSpan={6}>
							<FormTextField
								placeholder="Include optional instructions for this feature to specify what needs to be updated."
								value={value}
								onChange={(e) => setValue(e.target.value)}
								fullWidth
								multiline
								inputProps={{ maxLength: 300 }}
								icon={regular('message-lines')}
							/>
						</TableCell>
					</TableRow>
				)}
			</>
		);
	}

	return (
		<>
			<TableRow
				sx={{
					'.MuiTableCell-root': { borderBottom: showTextField ? 'none' : '' },
				}}>
				<TableCell>{feature.section.name_with_ordinal}</TableCell>
				<TableCell>{feature.name}</TableCell>
				<TableCell color="#4D4D4D">{feature.notes || '-'}</TableCell>

				<TableCell>
					<Rating feature={feature} />
				</TableCell>
				<TableCell>
					<Tooltip title={showTextField ? '' : 'Add Revision Note'}>
						<span>
							<IconButton
								icon={regular('message-lines')}
								disabled={!!value}
								onClick={() => setShowTextField(!showTextField)}
								buttonSx={{ backgroundColor: showTextField ? '#D9D9D9' : '' }}
							/>
						</span>
					</Tooltip>
				</TableCell>
				<TableCell>
					<IconButton
						loading={loading}
						icon={regular('times')}
						onClick={handleRemoveFromRevision}
					/>
				</TableCell>
			</TableRow>

			{showTextField && (
				<TableRow>
					<TableCell colSpan={6}>
						<FormTextField
							placeholder="Include optional instructions for this feature to specify what needs to be updated."
							value={value}
							onChange={(e) => setValue(e.target.value)}
							fullWidth
							multiline
							inputProps={{ maxLength: 300 }}
							icon={regular('message-lines')}
						/>
					</TableCell>
				</TableRow>
			)}
		</>
	);
};
