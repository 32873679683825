import { AutomationsApi } from '@rentcheck/api-frontend';
import {
	CreateAutomationParams,
	UpdateAutomationParams,
} from '@rentcheck/types';

import { Dispatch } from 'types';

export const getAll =
	(pageNumber: number = 0, pageSize: number = 20) =>
	async (dispatch: Dispatch) => {
		const result = await AutomationsApi.getAll(pageNumber, pageSize);

		dispatch({ type: 'SET_AUTOMATIONS', data: result.data });

		return result;
	};

const cleanUpAssignmentTypePayload = (
	payload: UpdateAutomationParams['data']
) => {
	if (!payload.assignment_type) {
		return;
	}

	switch (payload.assignment_type.type) {
		case 'residents':
			delete payload.assignment_type.teammate_emails;
			delete payload.assignment_type.emails;
			break;
		case 'teammates':
			delete payload.assignment_type.emails;
			delete payload.assignment_type.resident_type;
			break;
		case 'emails':
			delete payload.assignment_type.teammate_emails;
			delete payload.assignment_type.resident_type;
			break;
		case 'self-perform':
			delete payload.assignment_type.emails;
			delete payload.assignment_type.teammate_emails;
			delete payload.assignment_type.resident_type;
			break;
	}

	return payload;
};

const cleanUpSelectionTypePayload = (
	payload: UpdateAutomationParams['data']
) => {
	if (!payload.selection_type) {
		return;
	}

	switch (payload.selection_type.type) {
		case 'all_units':
			delete payload.selection_type.ids;
			break;
		case 'specific_units':
			break;
		case 'specific_teams':
			break;
	}

	return payload;
};

const cleanUpUpdatePayload = (payload: UpdateAutomationParams['data']) => {
	cleanUpAssignmentTypePayload(payload);
	cleanUpSelectionTypePayload(payload);

	return payload;
};

export const update =
	(id: string, payload: UpdateAutomationParams['data']) =>
	async (dispatch: Dispatch) => {
		const result = await AutomationsApi.update(
			id,
			cleanUpUpdatePayload(payload)
		);

		dispatch({ type: 'UPDATE_AUTOMATION', data: result });

		return result;
	};

export const deleteAutomation = (id: string) => async (dispatch: Dispatch) => {
	const result = await AutomationsApi.remove(id);

	dispatch({ type: 'REMOVE_AUTOMATION', id });

	return result;
};

export const create =
	(payload: CreateAutomationParams['data']) => async (dispatch: Dispatch) => {
		const result = await AutomationsApi.create(payload);

		dispatch({ type: 'CREATE_AUTOMATION', data: result });

		return result;
	};
