import { useState, MouseEvent, useMemo, useEffect } from 'react';
import _ from 'lodash';
import {
	Dialog,
	DialogActions,
	DialogContent,
	RadioGroup,
	Radio,
	FormControlLabel,
	Popover,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
	ApiAutomation,
	APIProperty,
	AutomationAssignMethod,
	ApiTeam,
	ApiTenant,
} from '@rentcheck/types';

import { Spacer, Button } from 'components';
import RecipientsList, { Recipient } from 'components/recipients-list';
import TeammatesList from 'components/teammates-list';
import { AssignedTeammates } from 'screens/modal-flows-controller/create-inspection/common/assigned-teammates';
import DialogTitle from 'screens/modal-flows-controller/create-inspection/common/inner-dialog-title';
import { numberAndPluralizeWord } from 'utils/helpers';

import assignMethodData from './assign-method-data';
import { AutomationRulesCard, SelectionCard, innerTransition } from '../common';
import AssignMethodList from './assign-method-list';
import AssignMethodRow from './assign-method-row';
import Selected from './selected';

interface Props {
	assignmentType: Partial<ApiAutomation['assignment_type']>;
	setAssignmentType: React.Dispatch<
		React.SetStateAction<Partial<ApiAutomation['assignment_type']>>
	>;
	selectionType: Partial<ApiAutomation['selection_type']>;
	properties: APIProperty[];
	teams: ApiTeam[];
	apiTeams: ApiTeam[];
}

const Assignment = ({
	assignmentType,
	setAssignmentType,
	selectionType,
	properties,
	teams,
	apiTeams,
}: Props) => {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [modalOpen, setModalOpen] = useState(false);
	const [menuAnchor, setMenuAnchor] = useState<HTMLDivElement | null>(null);
	const [typeField, setTypeField] =
		useState<ApiAutomation['assignment_type']['type']>();
	const [recipients, setRecipients] = useState<Recipient[]>(
		assignmentType.emails ?? []
	);
	const [notifyChecked, setNotifyChecked] = useState(false);
	const [notifyNote, setNotifyNote] = useState('');
	const [assignedTeammates, setAssignedTeammates] = useState<AssignedTeammates>(
		{}
	);

	useEffect(() => {
		const teammateEmails = assignmentType.teammate_emails;
		if (!teammateEmails) return;

		setAssignedTeammates(
			Object.fromEntries(
				Object.entries(teammateEmails).map(([key, emails]) => [
					key,
					emails.map((email) => ({ id: email, email })),
				])
			) as AssignedTeammates
		);
	}, []);

	useEffect(() => {
		if (Object.keys(assignmentType.teammate_emails ?? {}).length) return;

		setAssignedTeammates({});
	}, [assignmentType]);

	const handleTypeFieldMenu = (
		_: ApiAutomation['assignment_type']['type'],
		e: MouseEvent<HTMLDivElement>
	) => {
		setMenuAnchor(e.currentTarget);
	};

	const handleTypeField = (value: AutomationAssignMethod) => {
		setTypeField(value);
		setMenuAnchor(null);

		if (value === 'residents') {
			setAssignmentType({ type: value, resident_type: 'current' });
			handleClose();
		}

		if (value === 'self-perform') {
			setAssignmentType({ type: value });
			handleClose();
		}
	};

	const handleChangeResidentsType = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setAssignmentType({
			type: 'residents',
			resident_type: event.target
				.value as ApiAutomation['assignment_type']['resident_type'],
		});
	};

	const handleOpen = () => {
		setTypeField(assignmentType.type ?? 'residents');
		setModalOpen(true);
	};

	const handleClose = () => {
		setModalOpen(false);
	};

	const handleApply = () => {
		if (typeField === 'emails') {
			const tenantRecipientIds = recipients.map((r) => {
				if (typeof r !== 'string') {
					return (r as ApiTenant).id;
				}
				return r;
			});

			setAssignmentType({
				type: typeField,
				emails: tenantRecipientIds,
			});
		}

		if (typeField === 'teammates') {
			const teammateEmails = Object.fromEntries(
				Object.entries(assignedTeammates)
					.map(([teamId, users]) => [teamId, users.map((user) => user.email)])
					.filter(([, emails]) => emails.length > 0)
			);

			setAssignmentType({
				type: typeField,
				teammate_emails: teammateEmails,
			});
		}

		handleClose();
	};

	const providedTeams = useMemo(() => {
		if (selectionType.type === 'all_units') return apiTeams;

		if (selectionType.type === 'specific_units') {
			return apiTeams.filter((t) =>
				properties.map((p) => p.team?.id ?? '').includes(t.id)
			);
		}

		return teams;
	}, [apiTeams, teams, properties, selectionType.type]);

	const { applyButtonText, applyButtonDisabled } = useMemo(() => {
		if (typeField === 'teammates') {
			const items = _.flatMap(assignedTeammates);

			const notEnoughKeys =
				Object.keys(assignedTeammates).length !== providedTeams.length;

			const hasEmptyArrays = Object.values(assignedTeammates).some(
				(emails) => emails.length === 0
			);

			return {
				applyButtonText: items.length
					? `Add ${numberAndPluralizeWord(items.length, 'teammate')}`
					: `Add teammates`,
				applyButtonDisabled: notEnoughKeys || hasEmptyArrays,
			};
		}

		if (typeField === 'emails') {
			return {
				applyButtonText: recipients.length
					? `Add ${numberAndPluralizeWord(recipients.length, 'recipient')}`
					: `Add recipients`,
				applyButtonDisabled: !recipients.length,
			};
		}

		return { applyButtonText: 'Save', applyButtonDisabled: false };
	}, [typeField, recipients, JSON.stringify(assignedTeammates), providedTeams]);

	if (!assignmentType.type) return null;

	const { title, description, caption } = assignMethodData[assignmentType.type];

	return (
		<>
			<AutomationRulesCard title="Assign to...">
				<>
					<Spacer height={2} />

					<SelectionCard
						title={title}
						description={description}
						caption={caption}
						onClick={handleOpen}>
						<Selected
							assignmentType={assignmentType}
							recipients={assignmentType.emails}
							assignedTeammates={assignmentType.teammate_emails}
						/>
					</SelectionCard>

					{assignmentType.type === 'residents' && (
						<>
							<RadioGroup
								style={{ paddingLeft: 5 }}
								value={assignmentType?.resident_type ?? 'current'}
								onChange={handleChangeResidentsType}>
								<FormControlLabel
									value="current"
									control={<Radio />}
									label="Current residents"
								/>
								<FormControlLabel
									value="future"
									control={<Radio />}
									label="Future residents"
								/>
							</RadioGroup>
						</>
					)}
				</>
			</AutomationRulesCard>

			<Spacer height={5} />

			<Dialog
				open={modalOpen}
				onClose={handleClose}
				TransitionComponent={innerTransition}
				fullScreen={mobileScreen}>
				<DialogTitle title="Assign Inspection" onClose={handleClose} />

				<DialogContent style={{ height: '60vh' }}>
					{!typeField && <AssignMethodList onChange={handleTypeField} />}

					{!!typeField && (
						<>
							<AssignMethodRow
								variant={typeField}
								onClick={handleTypeFieldMenu}
								icon={solid('caret-down')}
							/>
							<Popover
								open={!!menuAnchor}
								anchorEl={menuAnchor}
								PaperProps={{
									style: { width: mobileScreen ? '100%' : 544 },
								}}
								onClose={() => setMenuAnchor(null)}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}>
								<div style={{ padding: '0 10px' }}>
									<AssignMethodList onChange={handleTypeField} />
								</div>
							</Popover>
						</>
					)}

					{typeField === 'emails' && (
						<RecipientsList
							properties={properties}
							value={recipients}
							setValue={setRecipients}
						/>
					)}

					{typeField === 'teammates' && (
						<TeammatesList
							properties={properties}
							assignedTeammates={assignedTeammates}
							setAssignedTeammates={setAssignedTeammates}
							notifyChecked={notifyChecked}
							setNotifyChecked={setNotifyChecked}
							notifyNote={notifyNote}
							setNotifyNote={setNotifyNote}
							providedTeams={providedTeams}
							showNotify={false}
						/>
					)}
				</DialogContent>

				<DialogActions>
					<Button variant="text" color="secondary" onClick={handleClose}>
						Back
					</Button>

					<Button
						variant="contained"
						onClick={handleApply}
						disabled={applyButtonDisabled}>
						{applyButtonText}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default Assignment;
