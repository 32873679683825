import { AccountSettingsApi, SubscriptionsApi } from '@rentcheck/api-frontend';
import {
	ApiSubscription,
	ApiUser,
	UpdateUserSubscriptionParams,
} from '@rentcheck/types';
import { Dispatch } from 'types';

import * as ActiveProfileActions from './active-profile';

/**
 * Polls the subscription endpoint every 2 seconds until a subscription is found
 * @param currentSubId an optional chargebee sub id, if provided, the function will
 * wait for the subscription id to change before returning, this is useful for re-checkouts
 * where a user with a cancelled subscription needs to re-subscribe.
 * @returns the updated subscription
 */
export const waitForSubscriptionWebhook =
	(currentSub?: ApiSubscription | null) => async (dispatch: Dispatch) =>
		new Promise<ApiSubscription>((resolve, reject) => {
			const maxSubscriptionRetries = 15;
			let pollCount = 0;

			const intervalId = setInterval(() => {
				pollCount++;

				SubscriptionsApi.get()
					.then(async (subscription) => {
						if (!subscription) {
							return;
						}

						if (
							currentSub &&
							subscription.plan.sub_id === currentSub.plan.sub_id &&
							subscription.plan.plan_id === currentSub.plan.plan_id
						) {
							return;
						}

						clearInterval(intervalId);

						/**
						 * Refresh the user to get the updated teams and permission groups
						 * that were created after the subscription was created. Because the
						 * user and PGs are created after the subscription is created, we need
						 * to wait a few seconds to wait for a bit before this call.
						 */
						await dispatch(waitForProfileUpdate());

						dispatch({
							type: 'SET_SUBSCRIPTION',
							subscription,
						} as any);

						AccountSettingsApi.get().then((accountSettings) =>
							dispatch({
								type: 'SET_ACCOUNT_SETTINGS',
								value: accountSettings,
							})
						);

						resolve(subscription);
					})
					.catch(() => {});

				if (pollCount === maxSubscriptionRetries) {
					clearInterval(intervalId);
					reject();
				}
			}, 2000);
		});

export const waitForProfileUpdate = () => async (dispatch: Dispatch) =>
	new Promise<ApiUser>((resolve, reject) => {
		const maxProfileRetries = 15;
		let pollCount = 0;

		const intervalId = setInterval(() => {
			pollCount++;

			dispatch(ActiveProfileActions.refreshUser())
				.then((profile: ApiUser) => {
					if (!profile.permissions.allow_automation_editing) {
						return;
					}

					clearInterval(intervalId);

					resolve(profile);
				})
				.catch(() => {});

			if (pollCount === maxProfileRetries) {
				clearInterval(intervalId);
				reject();
			}
		}, 2000);
	});

export const getSubscription = () => async (dispatch: Dispatch) => {
	const sub = await SubscriptionsApi.get();

	if (!sub) {
		return sub;
	}

	dispatch({ type: 'SET_SUBSCRIPTION', subscription: sub } as any);

	AccountSettingsApi.get().then((accountSettings) =>
		dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: accountSettings })
	);

	return sub;
};

export const update =
	(data: UpdateUserSubscriptionParams['data']) =>
	async (dispatch: Dispatch) => {
		return SubscriptionsApi.update(data).then((response) => {
			dispatch({ type: 'SET_SUBSCRIPTION', subscription: response } as any);

			AccountSettingsApi.get().then((accountSettings) =>
				dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: accountSettings })
			);
		});
	};
