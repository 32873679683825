import { ReactNode, ReactNodeArray } from 'react';
import { Typography } from '@mui/material';

import { Spacer } from 'components';

interface RowProps {
	title: string;
	children: ReactNode | ReactNodeArray;
}

const StandardRow = ({ title, children }: RowProps) => {
	return (
		<>
			<Spacer height={5} />

			<Typography variant="overline" color="secondary">
				{title}
			</Typography>

			<Spacer height={1} />
			{children}
		</>
	);
};

export default StandardRow;
