import { Skeleton } from '@mui/material';
import { Column } from 'components';

const BillingOption = () => {
	return (
		<Column>
			<Skeleton variant="rectangular" height={173} sx={{ borderRadius: 1 }} />

			<Skeleton sx={{ mt: 3 }} width="30%" />
			<Skeleton width="60%" />
			<Skeleton width="40%" />
			<Skeleton width="50%" />
			<Skeleton width="65%" />
			<Skeleton width="35%" />
			<Skeleton width="55%" />
			<Skeleton width="40%" />
			<Skeleton width="40%" />
			<Skeleton width="50%" />
			<Skeleton width="65%" />
			<Skeleton width="35%" />
			<Skeleton width="55%" />
		</Column>
	);
};

export default BillingOption;
