import { MouseEvent } from 'react';
import styled from 'styled-components';
import { Divider, Typography, Chip } from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AutomationAssignMethod } from '@rentcheck/types';

import { Column, SpacedRow, Spacer } from 'components';

import assignMethodData from './assign-method-data';

interface Props {
	variant: AutomationAssignMethod;
	onClick: (
		value: AutomationAssignMethod,
		e: MouseEvent<HTMLDivElement>
	) => void;
	icon?: IconDefinition;
}

const AssignMethodRow = ({ variant, onClick, icon }: Props) => {
	const handleClick = (e: MouseEvent<HTMLDivElement>) => {
		onClick(variant, e);
	};

	const { title, description, caption } = assignMethodData[variant];

	return (
		<>
			<RowContainer onClick={handleClick}>
				<Column style={{ width: '100%' }}>
					<SpacedRow>
						<Typography variant="subtitle1">{title}</Typography>

						{caption && (
							<Chip variant="filled" label="Dynamic" color="primary" />
						)}
					</SpacedRow>

					<Spacer height={2} />
					<Typography color="#00000099">{description}</Typography>
				</Column>

				{icon && <FontAwesomeIcon icon={icon} style={{ marginRight: 10 }} />}
			</RowContainer>

			<Divider />
		</>
	);
};

const RowContainer = styled(SpacedRow)`
	transition: 0.2s background-color ease-in-out;
	cursor: pointer;
	border-radius: 4px;
	margin-bottom: 15px;
	margin-top: 10px;

	background-color: transparent;

	&:hover {
		background-color: #2d3ce60a;
	}
`;

export default AssignMethodRow;
