import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, Divider, Typography } from '@mui/material';
import IconButton from 'components/icon-button';
import { Column } from 'components/layout/Column';
import { Row, SpacedRow } from 'components/layout/Row';
import styled from 'styled-components';
import { mediaSize, spacingPx } from 'theme';
import typographyStyles from 'theme/typography';

interface Props {
	/**
	 * Main title for the dialog title
	 */
	title: string;

	/**
	 * A badge to be displayed to the right of the main title
	 */
	titleBadge?: string;

	bottomSubtitle?: string | React.ReactNode;

	/**
	 * The icon to display on the left side of the title.
	 */
	icon?: IconDefinition;
	iconColor?: string;

	/**
	 * Alternatively an image can be displayed taking the left side
	 * icon place
	 */
	image?: string;

	/**
	 * Icon buttons can be displayed on the right side
	 * of the title next to the close button, these will remain
	 * on the same row as the close button when the modal is resized
	 */
	mainActionButtons?: React.ReactNode;

	/**
	 * Secondary action buttons can be displayed on the right side
	 * of the title next to the close button and main icon buttons,
	 * on modal resize these will get folded below the subtitle
	 */
	secondaryActionButtons?: React.ReactNode;

	/**
	 * Tertiary action buttons can be displayed on the right side
	 * of the title below  the close button, main icon buttons and,
	 * secondary action buttons, on modal resize these will get folded
	 * below the secondary action buttons
	 */
	tertiaryActionButtons?: React.ReactNode;

	onClose?: () => void;
}

const DialogTitle = ({
	title,
	titleBadge,
	bottomSubtitle,
	icon,
	iconColor,
	image,
	mainActionButtons,
	secondaryActionButtons,
	tertiaryActionButtons,
	onClose,
}: Props) => {
	return (
		<>
			<Wrapper compact={false}>
				<SpacedRow>
					<Row>
						{!!icon && (
							<FontAwesomeIcon
								style={{ marginRight: 12 }}
								icon={icon}
								color={iconColor ?? '#60E58A'}
								size="lg"
							/>
						)}
						{!!image && (
							<img
								alt=""
								style={{ marginRight: 8, height: 26, width: 26 }}
								src={image}
							/>
						)}
						<Column>
							<Row>
								<Typography variant="h6">{title}</Typography>
								{!!titleBadge && (
									<Chip
										label={titleBadge}
										sx={{
											ml: 2,
											mt: -1,
											mb: -1,
											backgroundColor: '#DFFFE9',
											...typographyStyles.MuiTypography.styleOverrides.body2,
										}}
									/>
								)}
							</Row>
							{!!bottomSubtitle && (
								<Typography variant="body2" color="#00000099">
									{bottomSubtitle}
								</Typography>
							)}
						</Column>
					</Row>
					{/* Regular screens */}
					<Column>
						<ActionsContainer
							sx={{
								display: { xs: 'none', sm: 'flex' },
								justifyContent: 'flex-end',
							}}>
							{!!secondaryActionButtons && secondaryActionButtons}
							{!!mainActionButtons && mainActionButtons}
							{!!onClose && (
								<IconButton icon={regular('times')} onClick={onClose} />
							)}
						</ActionsContainer>
						{tertiaryActionButtons && (
							<ActionsContainer
								sx={{
									mt: 1,
									display: { xs: 'none', sm: 'flex' },
									justifyContent: 'flex-end',
								}}>
								{tertiaryActionButtons}
							</ActionsContainer>
						)}
					</Column>
					{/* Extra small screens close button*/}
					<ActionsContainer
						sx={{
							display: { xs: 'flex', sm: 'none' },
						}}>
						{!!onClose && (
							<IconButton icon={regular('times')} onClick={onClose} />
						)}
					</ActionsContainer>
				</SpacedRow>

				{/* Extra small screens */}
				<ActionsContainer sx={{ mt: 2, display: { xs: 'flex', sm: 'none' } }}>
					{!!secondaryActionButtons && secondaryActionButtons}
					{!!tertiaryActionButtons && (
						<Row style={{ marginLeft: 'auto' }}>{tertiaryActionButtons}</Row>
					)}
				</ActionsContainer>
			</Wrapper>

			<Divider />
		</>
	);
};

const Wrapper = styled(Column)<{ compact: boolean }>`
	background-color: #fff;

	padding-left: ${spacingPx(7)};
	padding-right: ${spacingPx(7)};

	${({ compact }) =>
		compact
			? `margin-top: ${spacingPx(2)}; margin-bottom: ${spacingPx(2)};`
			: `margin-top: ${spacingPx(5)}; margin-bottom: ${spacingPx(4)};`}

	@media ${mediaSize.mobile} {
		padding-left: ${spacingPx(3.5)};
		padding-right: ${spacingPx(3.5)};
	}
`;

const ActionsContainer = styled(Box)`
	flex-direction: row;
	gap: 0 ${spacingPx(2)};

	button {
		margin: 0;
	}
`;

export default DialogTitle;
