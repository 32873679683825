import { Typography } from '@mui/material';

interface TextProps {
	text: string;
}

const StandardText = ({ text }: TextProps) => {
	const textStyle = {
		padding: 8,
		borderRadius: 14,
		backgroundColor: '#F6F5FF',
	};

	return <Typography style={textStyle}>{text}</Typography>;
};

export default StandardText;
