import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';

import { APIProperty, ApiTeam, ApiAutomation } from '@rentcheck/types';
import { colors } from '@rentcheck/theme';

import { Spacer, Row } from 'components';
import { numberAndPluralizeWord } from 'utils/helpers';

interface Props {
	selectionType: Partial<ApiAutomation['selection_type']>;
	teams: ApiTeam[];
	properties: APIProperty[];
}

const Selected = ({ selectionType, teams, properties }: Props) => {
	const teamCount = () => {
		if (teams.length) return teams.length;

		if (selectionType.type === 'specific_teams' && selectionType.ids?.length)
			return selectionType.ids.length;

		return 0;
	};

	const propertiesCount = () => {
		if (properties.length) return properties.length;

		if (selectionType.type === 'specific_units' && selectionType.ids?.length)
			return selectionType.ids.length;

		return 0;
	};

	const itemCount =
		selectionType.type === 'specific_teams' ? teamCount() : propertiesCount();
	const word = selectionType.type === 'specific_teams' ? 'team' : 'unit';

	if (!itemCount || selectionType.type === 'all_units') return null;

	return (
		<>
			<Spacer height={4} />

			<Row>
				<FontAwesomeIcon
					icon={solid('plus-circle')}
					color={colors.primary.default}
					size="lg"
					style={{ marginRight: 8 }}
				/>

				<Typography variant="subtitle2" color={colors.primary.default}>
					{numberAndPluralizeWord(itemCount, word)} selected
				</Typography>
			</Row>
		</>
	);
};

export default Selected;
