import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { ListOptions } from '@rentcheck/types';
import { AutomationsApi } from '@rentcheck/api-frontend';

import { Dispatch } from 'types';
import { AutomationsActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

import useApiModel from './common/api-hook';

export const useStoreAutomations = (pageSize: number = 20) => {
	const dispatch: Dispatch = useDispatch();

	const automations = useTypedSelector((state) => state.automations);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		dispatch(AutomationsActions.getAll(0, pageSize))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	}, []);

	return { loading, automations };
};

type SortT = ListOptions['sort'];

const defaultSort: SortT = {
	sort_by: 'created_at',
	sort_type: 'DESC',
};

const emptyFilters = {};

export const useAutomations = (pageSize: number = 20) => {
	const hookData = useApiModel(
		AutomationsApi,
		defaultSort,
		emptyFilters,
		pageSize
	);

	return { automations: hookData.data, ..._.omit(hookData, 'data') };
};
