import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Grid } from '@mui/material';
import firebase from 'firebase';
import { useHistory } from 'react-router-dom';

import { Rules } from '@rentcheck/biz';

import { Screens, PlanGate } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { isRenter } from 'utils/helpers';

import AccountCard from './account-card';
import ImportsAccountCard from './imports/account-card';

const Account = () => {
	const history = useHistory();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const maintenanceSettingsEnabled = firebase
		.remoteConfig()
		.getValue('maintenance_settings_enabled')
		.asBoolean();

	const inspectionTemplatesEnabled = firebase
		.remoteConfig()
		.getValue('inspection_templates_enabled')
		.asBoolean();

	const displayInspectionTemplateCard =
		profile?.template_admin ||
		(!!profile &&
			!!subscription &&
			!isRenter(profile) &&
			inspectionTemplatesEnabled);

	return (
		<Screens.Container>
			<Screens.Header
				title="Account"
				subtitle="Manage your profile, subscription, and more."
			/>
			<Grid container columns={{ xs: 1, sm: 2, xl: 3 }} spacing={2}>
				<Grid item xs={1}>
					<AccountCard
						title="Profile"
						icon={regular('user')}
						body="View and edit your personal profile including your account email and password."
						onClick={() => history.push('/account/profile')}
					/>
				</Grid>
				{!!profile && !isRenter(profile) && (
					<Grid item xs={1}>
						<AccountCard
							title="Billing"
							icon={regular('credit-card')}
							body="View and manage subscription details and billing information. Download RentCheck invoices."
							onClick={() => history.push('/account/billing')}
						/>
					</Grid>
				)}
				{!!profile && !!subscription && !isRenter(profile) && (
					<Grid item xs={1}>
						<AccountCard
							title="Inspection Report Settings"
							icon={regular('file-alt')}
							body="Customize the content of your RentCheck inspection reports."
							onClick={() =>
								history.push('/account/inspection-report-settings')
							}
						/>
					</Grid>
				)}
				{displayInspectionTemplateCard && (
					<Grid item xs={1}>
						<AccountCard
							title="Inspection Templates"
							icon={regular('file-alt')}
							body="Customize RentCheck inspections by modifying our standard templates or create your own templates from scratch."
							onClick={() => history.push('/account/inspection-templates')}
						/>
					</Grid>
				)}
				<Grid item xs={1}>
					<PlanGate variant="professional">
						<AccountCard
							title="Inspection Automation"
							icon={regular('bolt-auto')}
							body="Automatically schedule inspections based on move-in and move-out date triggers."
							newUntil="2025-03-31"
							onClick={() => history.push('/account/automations')}
						/>
					</PlanGate>
				</Grid>
				<ImportsAccountCard />
				{!!profile && !!subscription && !isRenter(profile) && (
					<Grid item xs={1}>
						<AccountCard
							title="Property Configuration"
							icon={regular('door-open')}
							body="Customize the rooms and areas that can be configured on your units and buildings."
							onClick={() => history.push('/account/property-configuration')}
						/>
					</Grid>
				)}
				{Rules.AccountScreen.canViewInspectionMessaging(
					subscription,
					profile
				) && (
					<Grid item xs={1}>
						<AccountCard
							title="Inspection Messaging"
							icon={regular('message-smile')}
							body="Add and manage company contact information in inspection invitations and reminders so residents can easily reach out when needed."
							onClick={() => history.push('/account/inspection-messaging')}
						/>
					</Grid>
				)}
				{!!profile &&
					!!subscription &&
					!isRenter(profile) &&
					maintenanceSettingsEnabled && (
						<Grid item xs={1}>
							<AccountCard
								isBeta
								title="Maintenance Settings"
								icon={regular('wrench')}
								body="Manage maintenance account settings including resident-reported maintenance."
								onClick={() => history.push('/account/maintenance-settings')}
							/>
						</Grid>
					)}
			</Grid>
		</Screens.Container>
	);
};

export default Account;
