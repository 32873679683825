import { Typography } from '@mui/material';

import { ApiAutomation } from '@rentcheck/types';
import { colors } from '@rentcheck/theme';

import { Row, Spacer } from 'components';
import FormTextField from 'components/form-text-field';

import { AutomationRulesCard, FormHelperTextProps } from '../common';

interface Props {
	automation?: ApiAutomation;
	creationDate: Partial<ApiAutomation['creation_date']>;
	setCreationDate: React.Dispatch<
		React.SetStateAction<Partial<ApiAutomation['creation_date']>>
	>;
}

const DueDate = ({ creationDate, setCreationDate }: Props) => {
	const handleDueDate = (event: React.ChangeEvent<HTMLInputElement>) => {
		const numericValue = Number(event.target.value);
		const validValue = isNaN(numericValue) ? 0 : numericValue;

		setCreationDate((previousState) => ({
			...previousState,
			due_date_after_days: validValue > 99 ? 99 : validValue,
		}));
	};

	const creationDueDate =
		creationDate.due_date_after_days?.toString() ?? undefined;

	return (
		<>
			<AutomationRulesCard title="Set inspection due date...">
				<Row style={{ alignItems: 'flex-start' }}>
					<FormTextField
						variant="outlined"
						inputSx={{
							backgroundColor: colors.white,
							maxWidth: 70,
							paddingBottom: 0,
							marginBottom: 0,
							'& .MuiInputBase-input::placeholder': {
								color: colors.gray.dark,
								opacity: 1,
							},
						}}
						inputProps={{ type: 'tel', max: 99, min: 0 }}
						placeholder="0"
						value={creationDueDate}
						onChange={handleDueDate}
						required={creationDate.due_date_after_days === undefined}
						FormHelperTextProps={FormHelperTextProps}
					/>

					<Spacer width={2} />

					<Typography style={{ paddingTop: 30 }}>
						days after inspection is created
					</Typography>
				</Row>
			</AutomationRulesCard>

			<Spacer height={5} />
		</>
	);
};

export default DueDate;
