import { useCallback } from 'react';
import { Switch, Typography } from '@mui/material';

import { Column, Row } from 'components';
import { colors } from 'theme';

interface Props {
	title: string;
	caption: string;
	value: boolean;
	setValue: (value: boolean) => void;
}

const SwitchRow = ({ title, caption, value, setValue }: Props) => {
	const handleClick = useCallback(() => setValue(!value), [value, setValue]);

	return (
		<Row
			style={{
				alignItems: 'center',
				justifyContent: 'space-between',
				cursor: 'pointer',
				marginBottom: 15,
			}}
			onClick={handleClick}>
			<Column>
				<Typography variant="overline">{title}</Typography>
				<Typography variant="body2" color={colors.secondary} mt={1}>
					{caption}
				</Typography>
			</Column>
			<Column>
				<Row>
					<Typography variant="overline">{value ? 'On' : 'Off'}</Typography>
					<Switch checked={!!value} onChange={handleClick} />
				</Row>
			</Column>
		</Row>
	);
};

export default SwitchRow;
