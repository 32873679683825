import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, DialogContent, Typography } from '@mui/material';
import {
	BuildingsApi,
	CommunitiesApi,
	UnitsApi,
} from '@rentcheck/api-frontend';
import { Column, Row, SpacedRow } from 'components';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
	formattedAddress,
	numberAndPluralizeWord,
	propertyTypePlurals,
} from 'utils/helpers';

import { APIBuilding, APIProperty } from '@rentcheck/types';
import InfiniteScroll, { InfiniteScrollRef } from './infinite-scroll';
import ListSkeleton from './list-skeleton';

const apis = {
	unit: UnitsApi,
	building: BuildingsApi,
	community: CommunitiesApi,
};

type ApiKeys = keyof typeof apis;

interface Props {
	value: APIProperty[];
	setValue: (value: APIProperty[]) => void;
	search: string;
	propertyType: ApiKeys;
	pageSize?: number;
}

export default ({
	search,
	value,
	setValue,
	propertyType,
	pageSize = 100,
}: Props) => {
	const infiniteScrollRef = useRef<InfiniteScrollRef>(null);

	const [properties, setProperties] = useState<APIProperty[]>([]);
	const [totalResults, setTotalResults] = useState(0);
	const [loading, setLoading] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);

	const searchProperties = useCallback(
		_.debounce((keyword: string) => {
			!properties.length && setLoading(true);

			handleFetch(keyword, 0)
				.catch(() => {})
				.finally(() => setLoading(false));
		}, 300),
		[]
	);

	useEffect(() => {
		searchProperties(search);
	}, [search]);

	const handleFetch = async (keyword: string, from: number) => {
		setLoadingMore(true);

		return apis[propertyType]
			?.getAll(
				from / pageSize,
				pageSize,
				{ teamId: [], buildingId: [], inspectionType: [], search: keyword },
				{ sortBy: 'address', sortType: 'asc' }
			)
			.then((result) => {
				let newProperties = [];

				if (from === 0) {
					newProperties = result.data;
				} else {
					newProperties = [...properties, ...result.data];
				}

				setProperties(newProperties);
				setTotalResults(result.totalResults);

				return newProperties;
			})
			.finally(() => setLoadingMore(false));
	};

	const handleSelect = (p: APIProperty) => {
		if (isSelected(p)) {
			setValue(value.filter((vp) => vp.id !== p.id));
			return;
		}

		setValue([...value, p]);
	};

	const handleSelectAll = () => {
		setValue([...properties]);
	};

	const handleClear = () => {
		setValue([]);
	};

	const handleLoadMore = () => {
		infiniteScrollRef.current?.loadMore();
	};

	const isSelected = (p: APIProperty) => {
		return !!value.find((vp) => vp.id === p.id);
	};

	if (loading) {
		return (
			<DialogContent sx={{ paddingTop: 0 }}>
				<ListSkeleton />
			</DialogContent>
		);
	}

	return (
		<>
			<DialogContent
				sx={{ minHeight: 60, paddingTop: 0, paddingBottom: 0, flex: 0 }}>
				<SpacedRow
					style={{
						height: 48,
						padding: '6px 8px 6px 16px',
						backgroundColor: '#0000000A',
						borderRadius: 4,
					}}>
					<Typography fontWeight={500}>
						{numberAndPluralizeWord(
							properties.length,
							_.capitalize(propertyType),
							propertyTypePlurals[propertyType]
						)}
					</Typography>
					<Row>
						{value.length > 0 && (
							<Button variant="text" onClick={handleClear}>
								Clear
							</Button>
						)}
						{properties.length > value.length && (
							<Button variant="text" onClick={handleSelectAll}>
								Select
							</Button>
						)}
						{totalResults > properties.length && (
							<LoadingButton
								variant="text"
								onClick={handleLoadMore}
								loading={loading || loadingMore}>
								Load More
							</LoadingButton>
						)}
					</Row>
				</SpacedRow>
			</DialogContent>
			<DialogContent sx={{ paddingTop: 0, flex: 1 }}>
				{properties.map((p) => {
					const selected = isSelected(p);

					return (
						<RowContainer
							selected={selected}
							id={`select-property-${p.id}`}
							onClick={() => handleSelect(p)}>
							<Column>
								{!!(p as APIBuilding).name && (
									<>
										<Typography>{(p as APIBuilding).name}</Typography>
										<Typography variant="body2" color="#00000099">
											{formattedAddress(p)}
										</Typography>
									</>
								)}

								{!(p as APIBuilding).name && (
									<>
										<Typography>{p.address}</Typography>
										<Typography variant="body2" color="#00000099">
											{p.city}, {p.zip_code}
										</Typography>
									</>
								)}
							</Column>
							<Checkbox checked={selected} />
						</RowContainer>
					);
				})}

				<InfiniteScroll
					ref={infiniteScrollRef}
					filterState={search}
					objectName={'Properties'}
					offset={properties.length}
					fetch={(from) => handleFetch(search, from)}
					pageSize={pageSize}
				/>
			</DialogContent>
		</>
	);
};

const RowContainer = styled(SpacedRow)<{ selected: boolean }>`
	margin-top: 10px;
	padding: 15px 10px;
	transition: 0.2s background-color ease-in-out;
	cursor: pointer;
	border-radius: 4px;
	background-color: ${({ selected }) =>
		selected ? '#2D3CE614' : 'transparent'};

	&:hover {
		background-color: #2d3ce60a;
	}
`;
