import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ModalFlowActions } from 'store/actions';
import { modalTypes } from 'store/reducers/modal-flows';
import { useTypedSelector } from 'store/reducers/rootReducer';

import ConfirmationModal from './confirmation-modal';
import CreateMaintenanceReport from './create-maintenance-report';
import CreateTeam from './create-team';
import EditDueDate from './edit-due-date';
import EditRecipients from './edit-recipients';
import ErrorModal from './error-modal';
import { EditFeature, FeatureDetails, FeatureImages } from './feature-details';
import FlagFeature from './flag-feature';
import FlagImages from './flag-images';
import InspectionRevision from './inspection-revision';
import Resident from './resident';
import SendReminder from './send-reminder';
import ShareInspection from './share-inspection';
import ShareMaintenanceReport from './share-maintenance-report';
import UpgradeSubscription from './upgrade-subscription';

import CreateInspection from './create-inspection';
import CreateInspectionTemplate from './create-inspection-template';
import CreatePermissionGroup from './create-permission-group';
import CreateWorkOrderAppFolio from './create-work-order-appfolio';
import CreateWorkOrderJenark from './create-work-order-jenark';
import CreateWorkOrderLatchel from './create-work-order-latchel';
import CreateWorkOrderRentManager from './create-work-order-rent-manager';
import CreateWorkOrderYardi from './create-work-order-yardi';
import EditPermissionGroups from './edit-users-permission-groups';
import InspectionTemplate from './inspection-template';
import InspectionTimeline from './inspection-timeline';
import InviteUsers from './invite-users';
import ManageAccess from './manage-access';
import RequestSignatures from './request-signatures';
import RevisionNotes from './revision-notes';
import Automation from './automation';

const ModalFlowsController = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	const modalState = useTypedSelector((state) => state.modalFlows);

	const [locationPath, setLocationPath] = useState(location.pathname);

	useEffect(() => {
		if (location.pathname === locationPath) {
			return;
		}

		// We need a state variable here to ensure the url actually changed
		// and we didn't just trigger a reload on react because of a code change.
		// This has no impact on deployed code but makes working on modals
		// easier by preventing them from dismissing themselves while making changes
		setLocationPath(location.pathname);
		dispatch(ModalFlowActions.closeCurrentModal());
	}, [location.pathname]);

	return (
		<>
			{modalTypes.map((type) => {
				const open = !!modalState.modals.find((m) => m.type === type);

				if (!open) {
					return null;
				}

				switch (type) {
					case 'Create Inspection':
						return <CreateInspection open={open} key={type} />;
					case 'Flag Feature':
						return <FlagFeature open={open} key={type} />;
					case 'Resident':
						return <Resident open={open} key={type} />;
					case 'Create Work Order Latchel':
						return <CreateWorkOrderLatchel open={open} key={type} />;
					case 'Create Work Order AppFolio':
						return <CreateWorkOrderAppFolio open={open} key={type} />;
					case 'Create Work Order Yardi':
						return <CreateWorkOrderYardi open={open} key={type} />;
					case 'Create Work Order Jenark':
						return <CreateWorkOrderJenark open={open} key={type} />;
					case 'Create Work Order Rent Manager':
						return <CreateWorkOrderRentManager open={open} key={type} />;
					case 'Create Maintenance Report':
						return <CreateMaintenanceReport open={open} key={type} />;
					case 'Edit Due Date':
						return <EditDueDate open={open} key={type} />;
					case 'Edit Recipients':
						return <EditRecipients open={open} key={type} />;
					case 'Send Reminder':
						return <SendReminder open={open} key={type} />;
					case 'Feature Details':
						return <FeatureDetails open={open} key={type} />;
					case 'Feature Images':
						return <FeatureImages open={open} key={type} />;
					case 'Flag Images':
						return <FlagImages open={open} key={type} />;
					case 'Edit Feature':
						return <EditFeature open={open} key={type} />;
					case 'Inspection Revision':
						return <InspectionRevision open={open} key={type} />;
					case 'Create Team':
						return <CreateTeam open={open} key={type} />;
					case 'Share Maintenance Report':
						return <ShareMaintenanceReport open={open} key={type} />;
					case 'Share Inspection':
						return <ShareInspection open={open} key={type} />;
					case 'Upgrade Subscription':
						return <UpgradeSubscription open={open} key={type} />;
					case 'Create Inspection Template':
						return <CreateInspectionTemplate open={open} key={type} />;
					case 'View Inspection Template':
						return <InspectionTemplate open={open} key={type} />;
					case 'Invite Users':
						return <InviteUsers open={open} key={type} />;
					case 'Edit Users Permission Groups':
						return <EditPermissionGroups open={open} key={type} />;
					case 'Revision Notes':
						return <RevisionNotes open={open} key={type} />;
					case 'Manage Access':
						return <ManageAccess open={open} key={type} />;
					case 'Create Permission Group':
						return <CreatePermissionGroup open={open} key={type} />;
					case 'Request Signatures':
						return <RequestSignatures open={open} key={type} />;
					case 'Inspection Timeline':
						return <InspectionTimeline open={open} key={type} />;
					case 'Automation':
						return <Automation open={open} key={type} />;
					default:
						return null;
				}
			})}

			<ConfirmationModal open={!!modalState.confirmation_modal} />
			<ErrorModal open={!!modalState.error_modal} />
		</>
	);
};

export default ModalFlowsController;
