import _ from 'lodash';
import { SelectChangeEvent } from '@mui/material';

import { ApiAutomation } from '@rentcheck/types';
import { colors } from '@rentcheck/theme';

import { Column, Row, Spacer } from 'components';
import FormTextField from 'components/form-text-field';

import TriggerType from './trigger-type';
import SelectWithHelperText from './select-with-helper-text';
import { AutomationRulesCard, FormHelperTextProps } from '../common';

type UnitAndCondition = Omit<
	ApiAutomation['creation_date'],
	'value' | 'due_date_after_days'
>;

const getMaxUnitValue = (unit?: ApiAutomation['creation_date']['unit']) => {
	if (!unit) return 99;

	return unit === 'days' ? 99 : 51;
};

interface Props {
	existingAutomation?: ApiAutomation;
	creationDate: Partial<ApiAutomation['creation_date']>;
	setCreationDate: React.Dispatch<
		React.SetStateAction<Partial<ApiAutomation['creation_date']>>
	>;
	triggerType?: ApiAutomation['trigger_type'];
	setTriggerType: (triggerType?: ApiAutomation['trigger_type']) => void;
}

const CreationDateAndTriggerType = ({
	existingAutomation,
	creationDate,
	setCreationDate,
	triggerType,
	setTriggerType,
}: Props) => {
	const handleUnitAndCondition =
		(key: keyof UnitAndCondition) => (event: SelectChangeEvent) => {
			const selectValue = event.target
				.value as ApiAutomation['creation_date'][typeof key];

			setCreationDate((previousState) => {
				const updatedState = {
					...previousState,
					[key]: selectValue,
				};

				if (key === 'unit') {
					const maxUnitValue = getMaxUnitValue(
						selectValue as ApiAutomation['creation_date']['unit']
					);

					if ((updatedState.value ?? 0) > maxUnitValue) {
						updatedState.value = maxUnitValue;
					}
				}

				return updatedState;
			});
		};

	const handleCreationDateValue = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const numericValue = Number(event.target.value);
		const validValue = isNaN(numericValue) ? 0 : numericValue;
		const maxUnitValue = getMaxUnitValue(creationDate.unit);

		setCreationDate((previousState) => ({
			...previousState,
			value: validValue > maxUnitValue ? maxUnitValue : validValue,
		}));
	};

	const creationDateValue = creationDate.value?.toString() ?? undefined;

	return (
		<>
			<AutomationRulesCard title="Create this inspection...">
				<Column>
					<Spacer height={2} />

					<Row style={{ alignItems: 'flex-start' }}>
						<FormTextField
							variant="outlined"
							inputSx={{
								backgroundColor: colors.white,
								maxWidth: 70,
								marginTop: 0,
								paddingBottom: 0,
								marginBottom: 0,
								'& .MuiInputBase-input::placeholder': {
									color: colors.gray.dark,
									opacity: 1,
								},
							}}
							inputProps={{
								type: 'tel',
								max: getMaxUnitValue(creationDate.unit),
								min: 0,
							}}
							placeholder="0"
							value={creationDateValue}
							onChange={handleCreationDateValue}
							required={creationDate.value === undefined}
							FormHelperTextProps={FormHelperTextProps}
						/>

						<Spacer width={2} />

						<SelectWithHelperText
							sx={{
								backgroundColor: colors.white,
								color: !creationDate.unit ? colors.gray.dark : '',
							}}
							variant="outlined"
							displayEmpty
							value={creationDate.unit}
							onChange={handleUnitAndCondition('unit')}
							options={[
								{ value: 'days', label: 'Days' },
								{ value: 'weeks', label: 'Weeks' },
							]}
							renderValue={(value) => {
								if (!value) return 'Days/Weeks';
								return _.capitalize(value as string);
							}}
						/>
					</Row>
					<Spacer height={1} />

					<SelectWithHelperText
						sx={{
							backgroundColor: colors.white,
							color: !creationDate.condition ? colors.gray.dark : '',
						}}
						variant="outlined"
						displayEmpty
						value={creationDate.condition}
						onChange={handleUnitAndCondition('condition')}
						options={[
							{ value: 'before', label: 'Before' },
							{ value: 'after', label: 'After' },
						]}
						renderValue={(value) => {
							if (!value) return 'Before/After';
							return _.capitalize(value as string);
						}}
					/>
					<Spacer height={1} />

					<TriggerType
						existingAutomation={existingAutomation}
						triggerType={triggerType}
						setTriggerType={setTriggerType}
					/>
				</Column>
			</AutomationRulesCard>

			<Spacer height={5} />
		</>
	);
};

export default CreationDateAndTriggerType;
