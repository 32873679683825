import { Chip } from '@mui/material';

import { ApiAutomation } from '@rentcheck/types';

import typographyStyles from 'theme/typography';

interface Props {
	automation: ApiAutomation;
}

const OnChip = ({ automation }: Props) => {
	if (!automation.active) return null;

	return (
		<Chip
			label="ON"
			sx={{
				ml: 'auto',
				mt: -1,
				mb: -1,
				backgroundColor: '#ACFFC6',
				...typographyStyles.MuiTypography.styleOverrides.body2,
			}}
		/>
	);
};

export default OnChip;
