import { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	Dialog,
	DialogActions,
	DialogContent,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { ApiAutomation, APIProperty, ApiTeam } from '@rentcheck/types';

import { Dispatch } from 'types';
import { DialogTitle, Button } from 'components';
import {
	AutomationsActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useAutomationModalData } from 'hooks/modals';
import { useInspectionTemplates } from 'hooks/inspection-templates';
import { useAutomations } from 'hooks/automations';
import { useTeams } from 'hooks/teams';

import { useDisabledButton } from './helpers';
import AutomationSkeleton from './skeleton';
import Name from './name';
import SelectionType from './selection-type';
import InspectionTemplate from './inspection-template';
import CreationDateAndTriggerType from './creation-date-and-trigger-type';
import DueDate from './due-date';
import Assignment from './assignment';
import { useTypedSelector } from 'store/reducers/common';

interface Props {
	open: boolean;
}

const Automation = ({ open }: Props) => {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const { automations, loading: automationsLoading } = useAutomations(100);
	const { templates, loading: templatesLoading } = useInspectionTemplates({
		published_only: true,
		property_types: ['unit'],
		templateType: 'all-rooms',
	});
	const { teams: apiTeams, loading: teamsLoading } = useTeams();

	const modalData = useAutomationModalData();
	const { automation: existingAutomation } = modalData?.metadata;

	const [name, setName] = useState(
		existingAutomation?.name ?? 'Automation name'
	);
	const [selectionType, setSelectionType] = useState<
		Partial<ApiAutomation['selection_type']>
	>(existingAutomation?.selection_type ?? { type: 'all_units' });
	const [properties, setProperties] = useState<APIProperty[]>([]);
	const [teams, setTeams] = useState<ApiTeam[]>([]);
	const [template, setTemplate] = useState<
		Partial<ApiAutomation['inspection_template']>
	>({});
	const [fastTrack, setFastTrack] = useState(!!existingAutomation?.fast_track);
	const [creationDate, setCreationDate] = useState<
		Partial<ApiAutomation['creation_date']>
	>(existingAutomation ? existingAutomation.creation_date : {});
	const [triggerType, setTriggerType] = useState<
		ApiAutomation['trigger_type'] | undefined
	>(existingAutomation?.trigger_type);
	const [assignmentType, setAssignmentType] = useState<
		Partial<ApiAutomation['assignment_type']>
	>(
		existingAutomation?.assignment_type ?? {
			type: 'residents',
			resident_type: 'current',
		}
	);
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		if (!existingAutomation) return;

		setTemplate(
			templates.find(
				(t) => t.id === existingAutomation?.inspection_template.id
			) ?? {}
		);
	}, [existingAutomation, templates]);

	useEffect(() => {
		if (teamsLoading) return;
		if (teams.length) return;
		if (!existingAutomation) return;
		if (existingAutomation.selection_type.type === 'specific_units') return;
		if (existingAutomation.selection_type.type === 'all_units') {
			setTeams(apiTeams.map((t) => t));
			return;
		}
		if (existingAutomation.selection_type.type === 'specific_teams') {
			setTeams(
				apiTeams.filter((t) =>
					(existingAutomation.selection_type.ids ?? []).includes(t.id)
				)
			);
			return;
		}
	}, [apiTeams]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleSave = () => {
		setButtonLoading(true);

		if (existingAutomation) {
			dispatch(
				AutomationsActions.update(existingAutomation.id, {
					name,
					selection_type: selectionType as ApiAutomation['selection_type'],
					inspection_template_id: template?.id,
					fast_track: fastTrack,
					creation_date: creationDate as ApiAutomation['creation_date'],
					trigger_type: triggerType,
					assignment_type: assignmentType as ApiAutomation['assignment_type'],
				})
			)
				.then(() => {
					dispatch(
						SnackbarActions.showSuccess(`Success! ${name} automation saved.`)
					);
					dispatch(ModalFlowActions.closeCurrentModal());
					setButtonLoading(false);
				})
				.catch((e) => {
					dispatch(SnackbarActions.showError(e));
					setButtonLoading(false);
				});

			return;
		}

		dispatch(
			AutomationsActions.create({
				name,
				selection_type: selectionType,
				inspection_template_id: template?.id,
				fast_track: fastTrack,
				creation_date: creationDate,
				trigger_type: triggerType,
				assignment_type: assignmentType,
			} as any)
		)
			.then(() => {
				dispatch(
					SnackbarActions.showSuccess(
						`Success! ${name} created. Enable it to go live.`
					)
				);
				dispatch(ModalFlowActions.closeCurrentModal());
				setButtonLoading(false);
			})
			.catch((e) => {
				dispatch(SnackbarActions.showError(e));
				setButtonLoading(false);
			});
	};

	const allowedAutomationEditing = useMemo(
		() => profile.permissions.allow_automation_editing,
		[profile.permissions]
	);

	const showPermissionModal = () => {
		if (allowedAutomationEditing) return;

		dispatch(
			ModalFlowActions.showConfirmationModal({
				icon: solid('lock'),
				title: 'Permission Required',
				body1: [
					'Editing this automation requires additional permissions. Please ask your system admin to update your access.',
				],
				buttons: [
					{
						title: 'Ok',
						onClick: () => dispatch(ModalFlowActions.closeConfirmationModal()),
					},
				],
			})
		);
	};

	const isLoading = useMemo(
		() => templatesLoading || automationsLoading || teamsLoading,
		[templatesLoading, automationsLoading, teamsLoading]
	);

	const disabled = useDisabledButton(
		automations,
		name,
		selectionType,
		template,
		creationDate,
		assignmentType,
		fastTrack,
		apiTeams,
		teams,
		properties,
		triggerType,
		existingAutomation
	);

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={mobileScreen}>
			<DialogTitle
				title={existingAutomation ? 'Edit Automation' : 'Create Automation'}
				icon={solid('bolt-auto')}
				onClose={handleClose}
			/>

			<DialogContent
				onClick={allowedAutomationEditing ? undefined : showPermissionModal}>
				<div
					style={{
						pointerEvents: allowedAutomationEditing ? 'all' : 'none',
						opacity: allowedAutomationEditing ? 1 : 0.5,
					}}>
					{isLoading && <AutomationSkeleton />}

					{!isLoading && (
						<>
							<Name
								existingAutomation={existingAutomation}
								automations={automations}
								name={name}
								setName={setName}
							/>

							<SelectionType
								selectionType={selectionType}
								setSelectionType={setSelectionType}
								properties={properties}
								setProperties={setProperties}
								apiTeams={apiTeams}
								teams={teams}
								setTeams={setTeams}
								setTemplate={setTemplate}
								assignmentType={assignmentType}
								setAssignmentType={setAssignmentType}
							/>

							<InspectionTemplate
								templates={templates}
								template={template}
								setTemplate={setTemplate}
								fastTrack={fastTrack}
								setFastTrack={setFastTrack}
								properties={properties}
								teams={teams}
								apiTeams={apiTeams}
								selectionType={selectionType}
							/>
							<CreationDateAndTriggerType
								existingAutomation={existingAutomation}
								creationDate={creationDate}
								setCreationDate={setCreationDate}
								triggerType={triggerType}
								setTriggerType={setTriggerType}
							/>
							<DueDate
								creationDate={creationDate}
								setCreationDate={setCreationDate}
							/>
							<Assignment
								assignmentType={assignmentType}
								setAssignmentType={setAssignmentType}
								selectionType={selectionType}
								properties={properties}
								teams={teams}
								apiTeams={apiTeams}
							/>
						</>
					)}
				</div>
			</DialogContent>

			<DialogActions
				sx={{
					pointerEvents: allowedAutomationEditing ? 'all' : 'none',
					opacity: allowedAutomationEditing ? 1 : 0.5,
				}}>
				<Button onClick={handleClose} variant="text" color="secondary">
					Cancel
				</Button>

				<LoadingButton
					onClick={handleSave}
					disabled={disabled}
					loading={buttonLoading}>
					Save
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default Automation;
