import { Collapse, Paper, Snackbar, Typography } from '@mui/material';
import { Column, Row, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { Utils } from '@rentcheck/biz';
import { numberAndPluralizeWord } from 'utils/helpers';
import FileRequests from './file-requests';
import Header from './header';
import Imports from './imports';

export default () => {
	const fileRequests = useTypedSelector((state) => state.fileRequests);
	const imports = useTypedSelector((state) => state.imports.tasks);

	const [hidden, setHidden] = useState(false);
	const [closed, setClosed] = useState(false);

	useEffect(() => {
		setHidden(false);
		setClosed(false);
	}, [fileRequests.length, imports.length]);

	const objects = [...fileRequests, ...imports];
	const open = !closed && objects.length > 0;

	const inProgressCount =
		fileRequests.filter(Utils.FileRequests.isInProgress).length +
		imports.filter(Utils.Imports.isInProgress).length;

	return (
		<Snackbar open={open} style={{ bottom: 0, zIndex: 100000000 }}>
			<Paper sx={{ width: 336, borderRadius: '6px 6px 0px 0px' }}>
				<Header
					hidden={hidden}
					setHidden={setHidden}
					closed={closed}
					setClosed={setClosed}
				/>
				<Collapse in={!hidden}>
					{inProgressCount > 0 && (
						<Row style={{ padding: '8px 20px', backgroundColor: '#2D3CE614' }}>
							<Typography variant="body2">
								Preparing {numberAndPluralizeWord(inProgressCount, 'item')}...
							</Typography>
						</Row>
					)}

					<Column style={{ maxHeight: 332, overflowY: 'scroll' }}>
						<FileRequests />
						<Imports />
						<Spacer height={4} />
					</Column>
				</Collapse>
			</Paper>
		</Snackbar>
	);
};
