import { Tooltip } from '@mui/material';
import _ from 'lodash';

import { ApiAutomation } from '@rentcheck/types';
import { Utils } from '@rentcheck/biz';

import { Column, Row, SpacedRow } from 'components';

import StandardText from './components/standard-text';

export const getTemplateNameText = (
	template: ApiAutomation['inspection_template']
) => {
	return `${template.name} ${template.name !== template.internal_label ? `(${template.internal_label})` : ''}`;
};

export const getTriggerTypeText = (
	triggerType: ApiAutomation['trigger_type']
) => {
	return triggerType === 'tenant-move-in' ? 'Move-in date' : 'Move-out date';
};

export const getAssignmentTypeText = (
	assignmentType: ApiAutomation['assignment_type']
) => {
	if (assignmentType.type === 'self-perform') {
		return <StandardText text="Entire Team" />;
	}

	if (assignmentType.type === 'teammates') {
		const teammateEmails = _.uniq(
			_.flatten(Object.values(assignmentType.teammate_emails || {}))
		);

		return (
			<Tooltip
				title={
					<Column>
						{teammateEmails.map((email) => (
							<SpacedRow>{email}</SpacedRow>
						))}
					</Column>
				}>
				<Row>
					<StandardText text="Teammates" />
				</Row>
			</Tooltip>
		);
	}

	if (
		assignmentType.type === 'residents' &&
		assignmentType?.resident_type === 'current'
	) {
		return <StandardText text="Current Residents" />;
	}

	if (
		assignmentType.type === 'residents' &&
		assignmentType?.resident_type === 'future'
	) {
		return <StandardText text="Future Residents" />;
	}

	return (
		<Tooltip
			title={
				<Column>
					{assignmentType.emails?.map((email) => (
						<SpacedRow>{email}</SpacedRow>
					))}
				</Column>
			}>
			<Row>
				<StandardText
					text={Utils.Helpers.numberAndPluralizedWord(
						assignmentType.emails?.length ?? 0,
						'Recipient'
					)}
				/>
			</Row>
		</Tooltip>
	);
};
