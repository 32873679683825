import { SelectionType } from '@rentcheck/types';

const selectionTypeData: Record<
	SelectionType,
	{
		title: string;
		description: string;
		caption?: string;
	}
> = {
	all_units: {
		title: 'All Units',
		description:
			'Automation will apply to all units you have access to, both now and in the future.',
		caption: 'Dynamic',
	},
	specific_teams: {
		title: 'Team Units',
		description:
			'Automation will dynamically apply to all units assigned to the selected team and will auto-update as unit selections change.',
		caption: 'Dynamic',
	},
	specific_units: {
		title: 'Specific Units',
		description:
			'Automations will apply only to the units you specifically select.',
	},
};

export default selectionTypeData;
