import { Grid } from '@mui/material';

import { useStoreAutomations } from 'hooks/automations';

import AutomationCard from './automation-card';
import AutomationsSkeleton from './automations-skeleton';

export default () => {
	const { automations, loading } = useStoreAutomations(100);

	if (loading) {
		return <AutomationsSkeleton />;
	}

	return (
		<Grid container columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
			{automations.map((a) => (
				<AutomationCard automation={a} />
			))}
		</Grid>
	);
};
