import { ApiAutomation } from '@rentcheck/types';

export type ActionTypes =
	| 'SET_AUTOMATIONS'
	| 'CREATE_AUTOMATION'
	| 'UPDATE_AUTOMATION'
	| 'REMOVE_AUTOMATION'
	| 'SIGNOUT_SUCCESS';

export type State = ApiAutomation[];

interface Action {
	type: ActionTypes;
	data: ApiAutomation[] | ApiAutomation;
	id: string;
}

export default (state: State = [], action: Action): State => {
	switch (action.type) {
		case 'SET_AUTOMATIONS':
			if (!action.data) return state;

			return action.data as ApiAutomation[];

		case 'CREATE_AUTOMATION':
			if (!action.data) return state;

			return [...state, action.data as ApiAutomation];

		case 'UPDATE_AUTOMATION':
			if (!action.data) return state;

			return state.map((auto) =>
				auto.id === (action.data as ApiAutomation).id
					? (action.data as ApiAutomation)
					: auto
			);

		case 'REMOVE_AUTOMATION':
			if (!action.id) return state;

			return state.filter((a) => a.id !== action.id);

		case 'SIGNOUT_SUCCESS':
			return [];

		default:
			return state;
	}
};
