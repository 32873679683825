import { useMemo } from 'react';
import _ from 'lodash';

import { ApiAutomation, APIProperty, ApiTeam } from '@rentcheck/types';

const stringArraysHaveSameItems = (arr1: string[], arr2: string[]) => {
	if (arr1.length !== arr2.length) return false;

	return arr1.sort().every((value, index) => value === arr2.sort()[index]);
};

const selectionTypeIsUnchanged = (
	selectionType: Partial<ApiAutomation['selection_type']>,
	existingSelectionType: Partial<ApiAutomation['selection_type']>
) => {
	if (selectionType.type !== existingSelectionType.type) return false;

	if (selectionType.type !== 'all_units') {
		return stringArraysHaveSameItems(
			selectionType.ids ?? [],
			existingSelectionType.ids ?? []
		);
	}

	return true;
};

const creationDateIsUnchanged = (
	creationDate: Partial<ApiAutomation['creation_date']>,
	existingCreationDate: Partial<ApiAutomation['creation_date']>
) => {
	return (
		creationDate.condition === existingCreationDate.condition &&
		creationDate.unit === existingCreationDate.unit &&
		creationDate.due_date_after_days ===
			existingCreationDate.due_date_after_days &&
		creationDate.value === existingCreationDate.value
	);
};

const assignmentTypeIsUnchanged = (
	assignmentType: Partial<ApiAutomation['assignment_type']>,
	existingAssignmentType: Partial<ApiAutomation['assignment_type']>
) => {
	if (assignmentType.type !== existingAssignmentType.type) {
		return false;
	}

	if (assignmentType.type === 'emails') {
		return stringArraysHaveSameItems(
			assignmentType.emails ?? [],
			existingAssignmentType.emails ?? []
		);
	}

	if (assignmentType.type === 'teammates') {
		return stringArraysHaveSameItems(
			_.flatMap(assignmentType.teammate_emails ?? {}) ?? [],
			_.flatMap(existingAssignmentType.teammate_emails ?? {}) ?? []
		);
	}

	if (assignmentType.type === 'residents') {
		return (
			assignmentType.resident_type === existingAssignmentType.resident_type
		);
	}

	return true;
};

const teamIsMissingTeammate = (
	selectionType: Partial<ApiAutomation['selection_type']>,
	assignmentType: Partial<ApiAutomation['assignment_type']>,
	apiTeams: ApiTeam[],
	teams: ApiTeam[],
	properties: APIProperty[]
) => {
	if (assignmentType.type !== 'teammates') {
		return false;
	}

	const assignmentTypeTeams = Object.keys(assignmentType.teammate_emails ?? {});

	if (!assignmentTypeTeams.length) return true;

	if (selectionType.type === 'all_units') {
		return assignmentTypeTeams.length !== apiTeams.length;
	}

	if (selectionType.type === 'specific_units') {
		return (
			assignmentTypeTeams.length !==
			apiTeams.filter((t) =>
				properties.map((p) => p.team?.id ?? '').includes(t.id)
			).length
		);
	}

	return assignmentTypeTeams.length !== teams.length;
};

export const useDisabledButton = (
	automations: ApiAutomation[],
	name: string,
	selectionType: Partial<ApiAutomation['selection_type']>,
	template: Partial<ApiAutomation['inspection_template']>,
	creationDate: Partial<ApiAutomation['creation_date']>,
	assignmentType: Partial<ApiAutomation['assignment_type']>,
	fastTrack: boolean,
	apiTeams: ApiTeam[],
	teams: ApiTeam[],
	properties: APIProperty[],
	triggerType?: ApiAutomation['trigger_type'],
	existingAutomation?: ApiAutomation
) => {
	return useMemo(() => {
		const duplicateName = !!automations.find(
			(a) => a.name === name && a.id !== existingAutomation?.id
		);
		if (duplicateName) {
			return true;
		}

		const invalidField =
			!name.trim() ||
			!triggerType ||
			!template.id ||
			!selectionType.type ||
			(selectionType.type !== 'all_units' && !selectionType.ids?.length) ||
			creationDate.value === undefined ||
			creationDate.due_date_after_days === undefined ||
			!creationDate.unit ||
			!creationDate.condition ||
			(assignmentType.type !== 'self-perform' &&
				!assignmentType.emails?.length &&
				!assignmentType.resident_type &&
				!_.flatMap(assignmentType.teammate_emails ?? {}).length) ||
			teamIsMissingTeammate(
				selectionType,
				assignmentType,
				apiTeams,
				teams,
				properties
			);

		if (!existingAutomation) return invalidField;

		const hasNoChanges =
			name === existingAutomation.name &&
			template?.id === existingAutomation.inspection_template.id &&
			fastTrack === existingAutomation.fast_track &&
			selectionTypeIsUnchanged(
				selectionType,
				existingAutomation.selection_type
			) &&
			assignmentTypeIsUnchanged(
				assignmentType,
				existingAutomation.assignment_type
			) &&
			creationDateIsUnchanged(creationDate, existingAutomation.creation_date);

		return hasNoChanges || invalidField;
	}, [
		automations,
		name,
		triggerType,
		fastTrack,
		template?.id,
		selectionType.ids,
		selectionType.type,
		creationDate.condition,
		creationDate.due_date_after_days,
		creationDate.unit,
		creationDate.value,
		assignmentType.type,
		assignmentType.emails,
		assignmentType.resident_type,
		assignmentType.teammate_emails,
		existingAutomation?.id,
		apiTeams,
		teams,
		properties,
	]);
};
