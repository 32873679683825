import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Card,
	CardContent,
	Grid,
	Switch,
	Tooltip,
	Typography,
} from '@mui/material';
import { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { ApiAutomation } from '@rentcheck/types';

import { Button, PlanGate, Row, SpacedRow, Spacer } from 'components';
import PlanGateAnimatedIcon from 'components/plan-gate/animated-icon';
import { PlanGateContext } from 'components/plan-gate/context';
import {
	AutomationsActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/common';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

import OnChip from './components/on-chip';
import RentCheckIcon from './components/rentcheck-icon';
import SpacedRowOrFlexEnd from './components/spaced-row-or-flex-end';
import StandardRow from './components/standard-row';
import StandardText from './components/standard-text';
import {
	getAssignmentTypeText,
	getTemplateNameText,
	getTriggerTypeText,
} from './helpers';

interface Props {
	automation: ApiAutomation;
}

const AutomationCardContent = ({ automation }: Props) => {
	const context = useContext(PlanGateContext);

	const dispatch: Dispatch = useDispatch();
	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const triggerTypeText = useMemo(
		() => getTriggerTypeText(automation.trigger_type),
		[automation.trigger_type]
	);

	const handleDelete = (e?: React.MouseEvent) => {
		e?.stopPropagation();
		e?.preventDefault();

		dispatch(
			ModalFlowActions.showConfirmationModal({
				icon: solid('raccoon'),
				title: 'Delete Automation?',
				body1: [
					`Are you sure you want to delete the ${automation.name} automation? This cannot be undone.`,
				],
				buttons: [
					{
						title: 'Cancel',
						onClick: () => dispatch(ModalFlowActions.closeConfirmationModal()),
					},
					{
						title: 'Delete',
						color: 'error',
						onClick: () =>
							dispatch(AutomationsActions.deleteAutomation(automation.id)).then(
								() => {
									dispatch(ModalFlowActions.closeConfirmationModal());
									dispatch(
										SnackbarActions.showSuccess(
											`Success! ${automation.name} automation deleted.`
										)
									);
								}
							),
					},
				],
			})
		);
	};

	const handleUpdate = (e?: React.MouseEvent) => {
		e?.stopPropagation();
		e?.preventDefault();

		dispatch(
			AutomationsActions.update(automation.id, { active: !automation.active })
		).then((result) => {
			if (!result.active) {
				dispatch(
					SnackbarActions.showSuccess(
						`${automation.name} automation turned off.`
					)
				);

				return;
			}

			Analytics.trackEvent('automation_turned_on', {
				subscription_id: subscription?.id ?? '',
				chargebee_subscription_id: subscription?.plan.sub_id,
			});

			dispatch(
				SnackbarActions.showSuccess(
					`Success! ${automation.name} automation is live.`
				)
			);
		});
	};

	if (!profile) {
		return null;
	}

	return (
		<CardContent sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
			<SpacedRow>
				<Row>
					<RentCheckIcon automation={automation} />

					<Typography variant="h6">{automation.name}</Typography>
				</Row>

				{!context.accessAllowed && (
					<PlanGateAnimatedIcon variant="blue" width={16} />
				)}
				{context.accessAllowed && <OnChip automation={automation} />}
			</SpacedRow>

			<StandardRow title="Create">
				<Row>
					<StandardText
						text={getTemplateNameText(automation.inspection_template)}
					/>
				</Row>
			</StandardRow>

			<StandardRow title="Inspection">
				<Row>
					<StandardText
						text={`${automation.creation_date.value} ${automation.creation_date.unit}`}
					/>
					<Spacer width={2} />

					<StandardText text={automation.creation_date.condition} />
					<Spacer width={2} />

					<StandardText text={triggerTypeText} />
					<Spacer width={2} />
				</Row>
			</StandardRow>

			<StandardRow title="Assign To">
				<Row>{getAssignmentTypeText(automation.assignment_type)}</Row>
			</StandardRow>

			<StandardRow title="Due">
				<Row>
					<StandardText
						text={`${automation.creation_date.due_date_after_days} days after inspection creation`}
					/>
				</Row>
			</StandardRow>

			<Spacer height={5} />
			<SpacedRowOrFlexEnd flexEnd={automation.is_rc_automation}>
				{!automation.is_rc_automation && (
					<Button
						variant="text"
						color="primary"
						onClick={handleDelete}
						disabled={!profile.permissions.allow_automation_editing}
						tooltip={
							!profile.permissions.allow_automation_editing
								? 'View-only permission'
								: ''
						}>
						Delete
					</Button>
				)}

				<Tooltip
					title="View-only permission"
					disableHoverListener={profile.permissions.allow_automation_editing}>
					<Row>
						<Switch
							disabled={!profile.permissions.allow_automation_editing}
							checked={automation.active}
							onClick={handleUpdate}
						/>
					</Row>
				</Tooltip>
			</SpacedRowOrFlexEnd>
		</CardContent>
	);
};

const AutomationCard = ({ automation }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const handleEditAutomation = () => {
		dispatch(ModalFlowActions.showAutomationModal({ automation }));
	};

	return (
		<Grid item xs={1} sx={{ display: 'flex' }}>
			<Card
				sx={{
					border: '1px solid #0000001F',
					cursor: 'pointer',
					':hover': { backgroundColor: '#eee' },
					width: '100%',
					opacity: automation.active ? 1 : 0.6,
				}}
				onClick={handleEditAutomation}>
				{automation.is_rc_automation && (
					<PlanGate variant="professional">
						<AutomationCardContent automation={automation} />
					</PlanGate>
				)}
				{!automation.is_rc_automation && (
					<AutomationCardContent automation={automation} />
				)}
			</Card>
		</Grid>
	);
};

export default AutomationCard;
