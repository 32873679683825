import { Grid, Skeleton } from '@mui/material';

export default () => {
	return (
		<Grid container columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
			<Grid item xs={1}>
				<Skeleton
					width="100%"
					height={350}
					variant="rectangular"
					sx={{ borderRadius: 1 }}
				/>
			</Grid>
			<Grid item xs={1}>
				<Skeleton
					width="100%"
					height={350}
					variant="rectangular"
					sx={{ borderRadius: 1 }}
				/>
			</Grid>
			<Grid item xs={1}>
				<Skeleton
					width="100%"
					height={350}
					variant="rectangular"
					sx={{ borderRadius: 1 }}
				/>
			</Grid>
		</Grid>
	);
};
