import {
	Typography,
	Select,
	MenuItem,
	Chip,
	SelectChangeEvent,
	Card,
	CardContent,
} from '@mui/material';

import { ApiAutomation } from '@rentcheck/types';
import { colors } from '@rentcheck/theme';

import { SpacedRow, Spacer } from 'components';

import selectionTypeData from './selection-type-data';

interface Props {
	typeField: Partial<ApiAutomation['selection_type']['type']>;
	handleTypeField: (event: SelectChangeEvent) => void;
}

const SelectType = ({ typeField, handleTypeField }: Props) => {
	const { description } = selectionTypeData[typeField];

	return (
		<Card elevation={0} sx={{ backgroundColor: '#2121210A' }}>
			<CardContent sx={{ padding: 2 }}>
				<Select
					fullWidth
					sx={{ backgroundColor: colors.white }}
					variant="outlined"
					displayEmpty
					renderValue={(value: ApiAutomation['selection_type']['type']) => {
						if (value === 'all_units') return 'All Units';
						if (value === 'specific_teams') return 'Team Units';
						return 'Specific Units';
					}}
					value={typeField}
					onChange={handleTypeField}>
					<MenuItem value="all_units">
						<SpacedRow style={{ width: '100%' }}>
							<Typography>All Units</Typography>

							<Chip variant="filled" label="Dynamic" color="primary" />
						</SpacedRow>
					</MenuItem>
					<MenuItem value="specific_teams">
						<SpacedRow style={{ width: '100%' }}>
							<Typography>Team Units</Typography>

							<Chip variant="filled" label="Dynamic" color="primary" />
						</SpacedRow>
					</MenuItem>
					<MenuItem value="specific_units">
						<Typography>Specific Units</Typography>
					</MenuItem>
				</Select>

				<Spacer height={2} />

				<Typography variant="body2" color="#00000099">
					{description}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default SelectType;
