import React, { ReactNode, ReactNodeArray } from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Typography, Chip, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { colors } from '@rentcheck/theme';

import { Spacer, SpacedRow, Row } from 'components';

interface AutomationRulesCardProps {
	title: string;
	children: ReactNode | ReactNodeArray;
}

export const AutomationRulesCard = ({
	title,
	children,
}: AutomationRulesCardProps) => {
	return (
		<Card
			elevation={0}
			sx={{ border: '1px solid #0000001F', backgroundColor: '#2121210A' }}>
			<CardContent sx={{ padding: 2 }}>
				<Typography variant="subtitle2">{title}</Typography>

				{children}
			</CardContent>
		</Card>
	);
};

interface SelectionCardProps {
	title: string;
	description: string;
	onClick: () => void;
	caption?: string;
	children?: ReactNode | ReactNodeArray;
}

export const SelectionCard = ({
	title,
	description,
	onClick,
	caption,
	children,
}: SelectionCardProps) => {
	return (
		<Card
			elevation={0}
			sx={{
				border: '1px solid #0000001F',
				cursor: 'pointer',
				'&&:hover': { backgroundColor: '#2121210A' },
			}}
			onClick={onClick}>
			<CardContent sx={{ padding: 2 }}>
				<SpacedRow>
					<Typography variant="subtitle1">{title}</Typography>

					<Row>
						{!!caption && (
							<Chip variant="filled" label="Dynamic" color="primary" />
						)}

						<FontAwesomeIcon
							icon={regular('pen')}
							color={colors.black}
							size="sm"
							style={{ marginLeft: 8 }}
						/>
					</Row>
				</SpacedRow>

				<Spacer height={2} />
				<Typography variant="body1">{description}</Typography>

				{children}
			</CardContent>
		</Card>
	);
};

export const innerTransition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const FormHelperTextProps = {
	sx: {
		backgroundColor: '#2121210A',
		margin: 0,
		paddingLeft: 1,
		paddingTop: 0.5,
	},
};
