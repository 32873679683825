import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { DialogTitle } from 'components';
import { Dispatch } from 'types';
import FormTextField from 'components/form-text-field';
import {
	ModalFlowActions,
	FeatureActions,
	SnackbarActions,
} from 'store/actions';
import { useRevisionNotesModalData } from 'hooks/modals';
import { useIsMobileBreakpoint } from 'utils/hooks';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const isMobile = useIsMobileBreakpoint();

	const [loading, setLoading] = useState(false);
	const [revisionNote, setRevisionNote] = useState('');

	const modalData = useRevisionNotesModalData();
	const feature = modalData?.metadata.feature;

	useEffect(() => {
		if (!feature) return;

		setRevisionNote(feature.revision_notes ?? '');
	}, [modalData]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleRevisionNotes = () => {
		setLoading(true);
		dispatch(
			FeatureActions.updateFeature(feature.id, {
				revision_notes: revisionNote,
			})
		)
			.then(() =>
				dispatch(
					SnackbarActions.showSuccess('Revision note added successfully!')
				)
			)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setLoading(false);
				handleClose();
			});
	};

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
			<DialogTitle
				title="Add Revision Note"
				icon={solid('pen-swirl')}
				onClose={handleClose}
			/>
			<DialogContent>
				<FormTextField
					placeholder="Include optional instructions for this feature to specify what needs to be updated."
					onChange={(e) => setRevisionNote(e.target.value)}
					value={revisionNote}
					fullWidth
					multiline
					inputProps={{ maxLength: 300 }}
					icon={regular('message-lines')}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} variant="text" color="secondary">
					Cancel
				</Button>
				<LoadingButton onClick={handleRevisionNotes} loading={loading}>
					Save
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
