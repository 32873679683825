import { useMemo } from 'react';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Typography, Autocomplete, MenuItem } from '@mui/material';

import {
	ApiAutomation,
	ApiInspectionTemplateDigest,
	APIProperty,
	ApiTeam,
} from '@rentcheck/types';
import { colors } from '@rentcheck/theme';

import { Spacer, Row, Column } from 'components';
import FormTextField from 'components/form-text-field';

import { AutomationRulesCard, FormHelperTextProps } from '../common';

interface Props {
	templates: ApiInspectionTemplateDigest[];
	template: Partial<ApiAutomation['inspection_template']>;
	setTemplate: React.Dispatch<
		React.SetStateAction<Partial<ApiAutomation['inspection_template']>>
	>;
	fastTrack: boolean;
	setFastTrack: (fastTrack: boolean) => void;
	properties: APIProperty[];
	teams: ApiTeam[];
	apiTeams: ApiTeam[];
	selectionType: Partial<ApiAutomation['selection_type']>;
}

const Template = ({
	templates,
	template,
	setTemplate,
	fastTrack,
	setFastTrack,
	properties,
	teams,
	apiTeams,
	selectionType,
}: Props) => {
	const handleTemplate = (
		selectedTemplate: ApiInspectionTemplateDigest | null
	) => {
		if (!selectedTemplate) {
			return setTemplate({});
		}

		const { id, name, internal_label, is_fast_track_enabled } =
			selectedTemplate;

		setTemplate({ id, name, internal_label, is_fast_track_enabled });

		if (!is_fast_track_enabled) setFastTrack(false);
	};

	const filteredTemplates = useMemo(() => {
		return templates.filter((t) => {
			if (selectionType.type === 'all_units') {
				return (
					t.is_rc_template ||
					apiTeams.every((team) => t.team_ids.includes(team.id))
				);
			}

			const teamIds = properties.map((p) => p.team?.id || '');
			if (selectionType.type === 'specific_units') {
				return (
					t.is_rc_template || teamIds.every((id) => t.team_ids.includes(id))
				);
			}

			if (selectionType.type === 'specific_teams') {
				return (
					t.is_rc_template ||
					teams.every((team) => t.team_ids.includes(team.id))
				);
			}

			return t;
		});
	}, [templates, selectionType.type, properties]);

	return (
		<>
			<Typography variant="overline">Automation Rules</Typography>
			<Spacer height={2} />

			<AutomationRulesCard title="Schedule a...">
				<>
					<Autocomplete
						fullWidth
						options={filteredTemplates}
						getOptionLabel={(o) => o.name}
						renderInput={(params) => (
							<FormTextField
								{...params}
								style={{ marginBottom: 0 }}
								inputSx={{ backgroundColor: colors.white }}
								placeholder="Choose Inspection"
								variant="outlined"
								required={!template.id}
								FormHelperTextProps={FormHelperTextProps}
							/>
						)}
						renderOption={(props, option) => (
							<MenuItem {...props} key={option.id}>
								<Column>
									{option.name}
									{option.name !== option.internal_label && (
										<Typography fontSize={12} color="#666">
											{option.internal_label}
										</Typography>
									)}
								</Column>
							</MenuItem>
						)}
						onChange={(_, newValue) => handleTemplate(newValue)}
						value={
							template.id
								? filteredTemplates.find((t) => t.id === template.id)
								: null
						}
					/>

					{template.is_fast_track_enabled && (
						<>
							<Spacer height={4} />

							<Row>
								<Checkbox
									checked={fastTrack}
									onClick={() => setFastTrack(!fastTrack)}
								/>

								<Column>
									<Row>
										<Typography variant="subtitle2">Fast-Track</Typography>

										<Spacer width={2} />

										<FontAwesomeIcon
											icon={solid('bolt')}
											color={colors.primary.default}
										/>
									</Row>
									<Spacer height={1} />

									<Typography variant="body2" color={colors.gray.dark}>
										A faster inspection experience designed for property
										management professionals.
									</Typography>
								</Column>

								<FontAwesomeIcon
									style={{ cursor: 'pointer' }}
									icon={regular('info-circle')}
									color="#00000099"
									size="1x"
									onClick={() =>
										window.open(
											'https://help.getrentcheck.com/en/articles/6151175-fast-track-inspections'
										)
									}
								/>
							</Row>
						</>
					)}
				</>
			</AutomationRulesCard>

			<Spacer height={5} />
		</>
	);
};

export default Template;
