import { CircularProgress, Typography } from '@mui/material';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { CenteredColumn, Row, Spacer } from 'components';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';
import { useOnScreen } from 'utils/hooks';

interface Props {
	objectName: string;
	offset: number;
	filterState: string;
	fetch: (from: number) => Promise<any[]>;
	pageSize: number;
}

export interface InfiniteScrollRef {
	loadMore: () => void;
}

const InfiniteScroll = forwardRef<InfiniteScrollRef, Props>((props, ref) => {
	const { fetch, offset, filterState, objectName, pageSize } = props;

	const isOutOfResults = (offset: number) => {
		return offset < pageSize;
	};

	const dispatch: Dispatch = useDispatch();

	const isVisibleRef = useRef<HTMLDivElement>(null);

	const isVisible = useOnScreen(isVisibleRef);

	const [needsToFetch, setNeedsToFetch] = useState(false);
	const [loading, setLoading] = useState(false);
	const [outOfResults, setOutOfResults] = useState(isOutOfResults(offset));

	const handleFetch = () => {
		setLoading(true);
		setNeedsToFetch(false);

		fetch(offset)
			.then((data) => {
				setOutOfResults(data.length - offset < pageSize);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setLoading(false);
			});
	};

	useImperativeHandle(ref, () => ({
		loadMore: () => setNeedsToFetch(true),
	}));

	useEffect(() => {
		if (!needsToFetch) {
			return;
		}

		if (outOfResults) {
			return;
		}

		if (loading) {
			return;
		}

		handleFetch();
	}, [needsToFetch, loading, outOfResults]);

	useEffect(() => {
		if (!isVisible) return;

		setNeedsToFetch(true);
	}, [isVisible]);

	useEffect(() => {
		isOutOfResults(offset) && setOutOfResults(true);
	}, [offset]);

	useEffect(() => {
		setOutOfResults(false);
	}, [filterState]);

	if (outOfResults) return null;

	if (pageSize > offset) {
		return null;
	}

	return (
		<div ref={isVisibleRef as any}>
			<Row style={{ justifyContent: 'center' }}>
				<CenteredColumn>
					<Spacer height={4} />
					<CircularProgress />
					<Typography color="#232e3a" align="center" fontWeight="500">
						We're fetching your {objectName}...
					</Typography>
				</CenteredColumn>
			</Row>
		</div>
	);
});

export default InfiniteScroll;
