import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Utils } from '@rentcheck/biz';

import { Screens, Button } from 'components';
import { Dispatch } from 'types';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/common';

import Automations from './automations';

export default () => {
	const dispatch: Dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);

	const handleCreateAutomation = () => {
		dispatch(ModalFlowActions.showAutomationModal({}));
	};

	const buildActions = useCallback(() => {
		const actions = [];

		if (
			Utils.Subscriptions.isOnProPlan(subscription) &&
			Utils.Subscriptions.hasInspectionAutomationAddons(subscription)
		) {
			actions.push(
				<Button
					variant="outlined"
					onClick={handleCreateAutomation}
					startIcon={<FontAwesomeIcon icon={solid('plus')} />}>
					Create
				</Button>
			);
		}

		return actions;
	}, [subscription]);

	return (
		<Screens.Container>
			<Screens.Header
				showBreadCrumbs
				title="Inspection Automation"
				subtitle="Automatically schedule inspections based on move-in and move-out date triggers."
				actions={buildActions()}
			/>
			<Automations />
		</Screens.Container>
	);
};
