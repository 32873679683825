import { useEffect, useState } from 'react';
import { TextField, Typography } from '@mui/material';

import { ApiAutomation } from '@rentcheck/types';

import { Column, Spacer } from 'components';

interface Props {
	existingAutomation?: ApiAutomation;
	automations: ApiAutomation[];
	name: string;
	setName: (name: string) => void;
}

const Name = ({ existingAutomation, automations, name, setName }: Props) => {
	const [error, setError] = useState(false);
	const [helperText, setHelperText] = useState('1-50 Characters');

	useEffect(() => {
		if (!name.trim()) {
			setHelperText('Required');
			setError(true);
			return;
		}

		if (
			!!automations.find(
				(a) => a.name === name && a.id !== existingAutomation?.id
			)
		) {
			setHelperText('Name already in use');
			setError(true);
			return;
		}

		setHelperText('1-50 Characters');
		setError(false);
	}, [name]);

	return (
		<Column>
			<Typography variant="overline">Automation Name</Typography>
			<Spacer height={2} />

			<TextField
				variant="outlined"
				placeholder="Automation name"
				helperText={helperText}
				error={error}
				value={name}
				onChange={(e) => setName(e.target.value)}
				inputProps={{ maxLength: 50 }}
			/>

			<Spacer height={5} />
		</Column>
	);
};

export default Name;
