import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as Sentry from '@sentry/react';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Profile } from '@rentcheck/types';

import { SalesApi } from '@rentcheck/api-frontend';
import { landing } from 'assets';
import { Column, Row, Spacer } from 'components';
import { AuthActions, SnackbarActions } from 'store/actions';
import { colors } from 'theme';

const Authentication = ({ planId }: { planId: string }) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const URLParameters = queryString.parse(location.search);

	const [email, setEmail] = useState(URLParameters.email as string);
	const [units, setUnits] = useState('');
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [userFound, setUserFound] = useState<boolean | null>(null);

	useEffect(() => {
		dispatch(AuthActions.signOut());
	}, []);

	const handleLogin = () => {
		history.push('/signin');
	};

	const handleError = (e: Error) => {
		if (e.message === 'User already exists') {
			setUserFound(true);
			setLoading(false);
			return;
		}

		Sentry.captureException(e);
		dispatch(SnackbarActions.showError());
	};

	const handleSubmit = async () => {
		if (!email || !units) {
			setError(true);
			return;
		}

		setLoading(true);

		const name = (URLParameters.name ?? '') as string;

		await SalesApi.createRevShareProspect(email, name, units, planId, [])
			.then((result: Profile) => {
				history.push('/');
				dispatch(
					AuthActions.signIn(result.email, result.temporary_password ?? '')
				);
			})
			.catch((e: any) => handleError(e))
			.finally(() => setLoading(false));
	};

	const handleUnits = (v: string) => {
		if (v === '') {
			setUnits('');
			return;
		}

		const newValue = parseFloat(v);
		if (isNaN(newValue)) return;

		setUnits(`${newValue}`);
	};

	if (userFound) {
		return (
			<Column>
				<Typography variant="h4" color={fullScreen ? colors.white : ''}>
					Spotted!
				</Typography>
				<Spacer height={5} />

				<Typography color={fullScreen ? colors.white : ''}>
					It looks like you already have an account!
				</Typography>
				<Spacer height={10} />

				<img alt="" src={landing.binoculars} />
				<Spacer height={10} />

				<LoadingButton
					variant="contained"
					color="primary"
					sx={{ borderRadius: 10 }}
					loading={loading}
					onClick={handleLogin}>
					Click here to Login
				</LoadingButton>
			</Column>
		);
	}

	return (
		<Column>
			<Typography variant="h4" color={fullScreen ? colors.white : ''}>
				Try RentCheck for free
			</Typography>
			<Spacer height={5} />

			<Typography color={fullScreen ? colors.white : ''}>
				Start a 30-day free trial and join thousands of property managers who
				are increasing profits, increasing visibility, and improving resident
				satisfaction with resident-led inspections.
			</Typography>
			<Spacer height={7} />

			<Row>
				<FontAwesomeIcon color="#51C978" icon={regular('check-circle')} />
				<Spacer width={2} />
				<Typography color={fullScreen ? colors.white : ''}>
					Email & Live Chat Support for you and your residents.
				</Typography>
			</Row>
			<Spacer height={1} />

			{planId === 'essential-plan---$1.00-(includes-30-day-free-trial)' && (
				<>
					<Row>
						<FontAwesomeIcon color="#51C978" icon={regular('check-circle')} />
						<Spacer width={2} />
						<Typography color={fullScreen ? colors.white : ''}>
							No credit card required.
						</Typography>
					</Row>
					<Spacer height={1} />
				</>
			)}

			<Row>
				<FontAwesomeIcon color="#51C978" icon={regular('check-circle')} />
				<Spacer width={2} />
				<Typography color={fullScreen ? colors.white : ''}>
					Cancel Anytime.
				</Typography>
			</Row>
			<Spacer height={7} />

			<form
				onSubmit={(e) => {
					e.preventDefault();
					handleSubmit();
				}}>
				<TextField
					id="text-field-email"
					label="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					error={error && !email}
					variant={fullScreen ? 'filled' : 'standard'}
					sx={{ input: { background: fullScreen ? 'white' : '' } }}
					fullWidth
				/>
				<Spacer height={5} />

				<TextField
					id="text-field-units"
					label="How many units do you manage?"
					value={units}
					onChange={(e) => handleUnits(e.target.value)}
					error={error && !units}
					variant={fullScreen ? 'filled' : 'standard'}
					sx={{ input: { background: fullScreen ? 'white' : '' } }}
					fullWidth
				/>
				<Spacer height={5} />

				{/* this div is necessary for button to be visible when in loading state */}
				<div style={{ backgroundColor: 'white', borderRadius: 50 }}>
					<LoadingButton
						id="button-signup"
						variant="contained"
						color="primary"
						type="submit"
						sx={{ borderRadius: 10, width: '100%' }}
						loading={loading}
						onClick={handleSubmit}>
						Start 30-day free trial
					</LoadingButton>
				</div>
			</form>
		</Column>
	);
};

export default Authentication;
