import { useMemo } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, Typography, Box } from '@mui/material';

import { ApiTeam } from '@rentcheck/types';

import { Column, Row, SpacedRow } from 'components';
import { numberAndPluralizeWord } from 'utils/helpers';

interface Props {
	options: ApiTeam[];
	value: ApiTeam[];
	setValue: (value: ApiTeam[]) => void;
	search: string;
}

export default ({ options, search, value, setValue }: Props) => {
	const handleSelect = (t: ApiTeam) => {
		if (isSelected(t)) {
			setValue(value.filter((v) => v.id !== t.id));
			return;
		}

		setValue([...value, t]);
	};

	const handleSelectAll = () => {
		setValue([...options]);
	};

	const handleClear = () => {
		setValue([]);
	};

	const isSelected = (p: ApiTeam) => {
		return !!value.find((vp) => vp.id === p.id);
	};

	const filteredTeams = useMemo(() => {
		return options.filter(
			(t) =>
				t.name.toLowerCase().includes(search.toLowerCase()) ||
				t.internal_label.toLowerCase().includes(search.toLowerCase())
		);
	}, [options, search]);

	return (
		<>
			<Box style={{ flex: '0 0 auto', padding: 1 }}>
				<SpacedRow
					style={{
						height: 48,
						padding: '6px 8px 6px 16px',
						backgroundColor: '#0000000A',
						borderRadius: 4,
					}}>
					<Typography fontWeight={500}>
						{numberAndPluralizeWord(options.length, 'team')}
					</Typography>
					<Row>
						{value.length > 0 && (
							<Button variant="text" onClick={handleClear}>
								Clear
							</Button>
						)}
						{options.length > value.length && (
							<Button variant="text" onClick={handleSelectAll}>
								Select
							</Button>
						)}
					</Row>
				</SpacedRow>
			</Box>

			<Box style={{ flex: '1 1 auto', overflowY: 'auto', padding: 1 }}>
				{filteredTeams.map((t) => {
					const selected = isSelected(t);

					return (
						<RowContainer
							selected={selected}
							id={`select-team-${t.id}`}
							onClick={() => handleSelect(t)}>
							<Column>
								<>
									<Typography>{t.name}</Typography>
									<Typography variant="body2" color="#00000099">
										{t.internal_label}
									</Typography>
								</>
							</Column>
							<Checkbox checked={selected} />
						</RowContainer>
					);
				})}
			</Box>
		</>
	);
};

const RowContainer = styled(SpacedRow)<{ selected: boolean }>`
	margin-top: 10px;
	padding: 15px 10px;
	transition: 0.2s background-color ease-in-out;
	cursor: pointer;
	border-radius: 4px;
	background-color: ${({ selected }) =>
		selected ? '#2D3CE614' : 'transparent'};

	&:hover {
		background-color: #2d3ce60a;
	}
`;
