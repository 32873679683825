import _ from 'lodash';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';

import { ApiAutomation } from '@rentcheck/types';
import { colors } from '@rentcheck/theme';

import { Spacer, Row } from 'components';
import { numberAndPluralizeWord } from 'utils/helpers';

interface Props {
	assignmentType: Partial<ApiAutomation['assignment_type']>;
	recipients?: string[];
	assignedTeammates?: Record<string, string[]>;
}

const Selected = ({ assignmentType, recipients, assignedTeammates }: Props) => {
	const recipientsCount = () => {
		if (recipients?.length) return recipients.length;

		if (assignmentType.type === 'emails' && recipients?.length)
			return recipients.length;

		return 0;
	};

	const teammatesCount = () => {
		if (!assignedTeammates) return 0;

		const assignedTeammatesArray = _.flatMap(assignedTeammates);
		if (assignedTeammatesArray.length) return assignedTeammatesArray.length;

		if (assignmentType.type === 'teammates' && assignedTeammatesArray.length)
			return assignedTeammatesArray.length;

		return 0;
	};

	const itemCount =
		assignmentType.type === 'emails' ? recipientsCount() : teammatesCount();
	const word = assignmentType.type === 'emails' ? 'email' : 'teammate';

	if (
		!itemCount ||
		assignmentType.type === 'self-perform' ||
		assignmentType.type === 'residents'
	)
		return null;

	return (
		<>
			<Spacer height={4} />

			<Row>
				<FontAwesomeIcon
					icon={solid('plus-circle')}
					color={colors.primary.default}
					size="lg"
					style={{ marginRight: 8 }}
				/>

				<Typography variant="subtitle2" color={colors.primary.default}>
					{numberAndPluralizeWord(itemCount, word)} selected
				</Typography>
			</Row>
		</>
	);
};

export default Selected;
