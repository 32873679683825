import { AutomationAssignMethod } from '@rentcheck/types';

import AssignMethodRow from './assign-method-row';

interface Props {
	onChange: (value: AutomationAssignMethod) => void;
}

const AssignMethodList = ({ onChange }: Props) => {
	return (
		<>
			<AssignMethodRow variant="residents" onClick={onChange} />

			<AssignMethodRow variant="teammates" onClick={onChange} />

			<AssignMethodRow variant="self-perform" onClick={onChange} />

			<AssignMethodRow variant="emails" onClick={onChange} />
		</>
	);
};

export default AssignMethodList;
