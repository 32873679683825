import { AutomationAssignMethod } from '@rentcheck/types';

const assignMethodData: Record<
	AutomationAssignMethod,
	{
		title: string;
		description: string;
		caption?: string;
	}
> = {
	residents: {
		title: 'Residents',
		description:
			'Invite upcoming or current residents to inspections, automatically updating with resident changes.',
		caption: 'Dynamic',
	},
	teammates: {
		title: 'Teammates',
		description:
			'Assign to selected teammates. Future automations will always go to the teammates you choose.',
	},
	'self-perform': {
		title: 'Entire Team',
		description:
			'All team members with access to the unit will be able to perform the inspection.',
	},
	emails: {
		title: 'Manually assign',
		description: 'Assign automations to specific email addresses you provide.',
	},
};

export default assignMethodData;
