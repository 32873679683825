import {
	ApiInspectionWithTemplate,
	ApiMaintenanceReport,
} from '@rentcheck/types';
import _ from 'lodash';
import {
	CreateInspectionMetadata,
	CreateInspectionTemplateMetadata,
	CreateMaintenanceReportMetadata,
	CreatePermissionGroupMetadata,
	CreateWorkOrderMetadata,
	EditDueDateMetadata,
	EditFeatureMetadata,
	EditRecipientsMetadata,
	FeatureDetailsMetadata,
	FeatureImageMetadata,
	FlagFeatureMetadata,
	FlagImagesMetadata,
	InspectionRevisionMetadata,
	InspectionTimelineMetadata,
	InviteUsersMetadata,
	ManageAccessMetadata,
	ModalType,
	RequestSignaturesMetadata,
	ResidentMetadata,
	SendReminderMetadata,
	ShareMetadata,
	ShowEditUsersPermissionGroupsMetadata,
	ShowInspectionTemplateMetadata,
	ShowRevisionNotesMetadata,
	AutomationModalMetadata,
} from 'store/reducers/modal-flows';
import { useTypedSelector } from 'store/reducers/rootReducer';

interface ModalData<T> {
	type: ModalType;
	metadata: T;
}

export const useCreateInspectionModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Create Inspection'
			) as ModalData<CreateInspectionMetadata>
	);
};

export const useCreateInspectionTemplateModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Create Inspection Template'
			) as ModalData<CreateInspectionTemplateMetadata>
	);
};

export const useInspectionTemplateModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'View Inspection Template'
			) as ModalData<ShowInspectionTemplateMetadata>
	);
};

export const useFlagFeatureModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Flag Feature'
			) as ModalData<FlagFeatureMetadata>
	);
};

export const useCreateWorkOrderModalData = (
	provider: 'Latchel' | 'Yardi' | 'AppFolio' | 'Jenark' | 'Rent Manager'
) => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === `Create Work Order ${provider}`
			) as ModalData<CreateWorkOrderMetadata>
	);
};

export const useCreateMaintenanceReportModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Create Maintenance Report'
			) as ModalData<CreateMaintenanceReportMetadata>
	);
};

export const useEditDueDateModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Edit Due Date'
			) as ModalData<EditDueDateMetadata>
	);
};

export const useEditRecipientsModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Edit Recipients'
			) as ModalData<EditRecipientsMetadata>
	);
};

export const useSendReminderModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Send Reminder'
			) as ModalData<SendReminderMetadata>
	);
};

export const useResidentModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Resident'
			) as ModalData<ResidentMetadata>
	);
};

export const useShareInspectionModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Share Inspection'
			) as ModalData<ShareMetadata<ApiInspectionWithTemplate>>
	);
};

export const useShareMaintenanceReportModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Share Maintenance Report'
			) as ModalData<ShareMetadata<ApiMaintenanceReport>>
	);
};

export const useFeatureDetailsModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Feature Details'
			) as ModalData<FeatureDetailsMetadata>
	);
};

export const useFeatureImageModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Feature Images'
			) as ModalData<FeatureImageMetadata>
	);
};

export const useFlagImagesModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Flag Images'
			) as ModalData<FlagImagesMetadata>
	);
};

export const useEditFeatureModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Edit Feature'
			) as ModalData<EditFeatureMetadata>
	);
};

export const useInspectionRevisionMetadata = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Inspection Revision'
			) as ModalData<InspectionRevisionMetadata>
	);
};

export const useInviteUsersModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Invite Users'
			) as ModalData<InviteUsersMetadata>
	);
};

export const useCreatePermissionGroupModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Create Permission Group'
			) as ModalData<CreatePermissionGroupMetadata>
	);
};

export const useInspectionTimelineModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Inspection Timeline'
			) as ModalData<InspectionTimelineMetadata>
	);
};

export const useRequestSignaturesModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Request Signatures'
			) as ModalData<RequestSignaturesMetadata>
	);
};

export const useEditUsersPermissionGroupsModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Edit Users Permission Groups'
			) as ModalData<ShowEditUsersPermissionGroupsMetadata>
	);
};

export const useRevisionNotesModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Revision Notes'
			) as ModalData<ShowRevisionNotesMetadata>
	);
};

export const useManageAccessModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Manage Access'
			) as ModalData<ManageAccessMetadata>
	);
};

export const useIsTopModal = (type: ModalType) => {
	return useTypedSelector(
		(state) => _.last(state.modalFlows.modals)?.type === type
	);
};

export const useAutomationModalData = () => {
	return useTypedSelector(
		(state) =>
			state.modalFlows.modals.find(
				(m) => m.type === 'Automation'
			) as ModalData<AutomationModalMetadata>
	);
};
