import { ReactNode, ReactNodeArray } from 'react';
import { SpacedRow, StartFromEndRow } from 'components';

const SpacedRowOrFlexEnd = ({
	flexEnd,
	children,
}: {
	flexEnd: boolean;
	children: ReactNode | ReactNodeArray;
}) => {
	if (flexEnd) {
		return <StartFromEndRow>{children}</StartFromEndRow>;
	}

	return <SpacedRow>{children}</SpacedRow>;
};

export default SpacedRowOrFlexEnd;
