import { Skeleton } from '@mui/material';
import { Column, Spacer } from 'components';

const AutomationSkeleton = () => {
	return (
		<Column>
			<Skeleton variant="text" width="25%" height={30} />
			<Skeleton variant="rectangular" width="100%" height={80} />
			<Spacer height={2} />

			<Skeleton variant="text" width="25%" height={30} />
			<Skeleton variant="rectangular" width="100%" height={120} />
			<Spacer height={2} />

			<Skeleton variant="text" width="25%" height={30} />
			<Skeleton variant="rectangular" width="100%" height={200} />
			<Spacer height={2} />

			<Skeleton variant="rectangular" width="100%" height={200} />
			<Spacer height={2} />

			<Skeleton variant="rectangular" width="100%" height={120} />
			<Spacer height={2} />

			<Skeleton variant="rectangular" width="100%" height={200} />
		</Column>
	);
};

export default AutomationSkeleton;
