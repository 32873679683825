import { ApiAutomation } from '@rentcheck/types';
import { landing } from 'assets';

interface Props {
	automation: ApiAutomation;
}

const RentCheckIcon = ({ automation }: Props) => {
	if (!automation.is_rc_automation) return null;

	return (
		<div
			style={{
				height: 38,
				width: 38,
				minWidth: 38,
				borderRadius: 19,
				marginRight: 5,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: '#F6F5FF',
			}}>
			<img
				alt="Default RentCheck Automation"
				src={landing.rentCheckLogoNotext}
				style={{ height: 17 }}
			/>
		</div>
	);
};

export default RentCheckIcon;
