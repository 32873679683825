import { Typography, Tooltip } from '@mui/material';

import { ApiAutomation } from '@rentcheck/types';
import { colors } from '@rentcheck/theme';

import SelectWithHelperText from './select-with-helper-text';

const getTriggerValue = (triggerType?: ApiAutomation['trigger_type']) => {
	if (triggerType === 'tenant-move-in') return 'Move-in date';

	return 'Move-out date';
};

interface Props {
	existingAutomation?: ApiAutomation;
	triggerType?: ApiAutomation['trigger_type'];
	setTriggerType: (triggerType?: ApiAutomation['trigger_type']) => void;
}

const TriggerType = ({
	existingAutomation,
	triggerType,
	setTriggerType,
}: Props) => {
	if (!existingAutomation) {
		return (
			<SelectWithHelperText
				sx={{
					backgroundColor: colors.white,
					color: !triggerType ? colors.gray.dark : '',
				}}
				variant="outlined"
				displayEmpty
				value={triggerType}
				onChange={(e) =>
					setTriggerType(e.target.value as ApiAutomation['trigger_type'])
				}
				options={[
					{ value: 'tenant-move-in', label: 'Move-in date' },
					{ value: 'tenant-move-out', label: 'Move-out date' },
				]}
				renderValue={(value) => {
					if (!value) return 'Choose Trigger';
					return getTriggerValue(value as ApiAutomation['trigger_type']);
				}}
			/>
		);
	}

	return (
		<Tooltip title="Cannot edit trigger">
			<Typography
				variant="subtitle2"
				sx={{
					backgroundColor: colors.white,
					padding: 1,
					borderRadius: 1,
				}}>
				{getTriggerValue(existingAutomation.trigger_type)}
			</Typography>
		</Tooltip>
	);
};

export default TriggerType;
