import { useState, useMemo } from 'react';
import _ from 'lodash';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	SelectChangeEvent,
	useMediaQuery,
	useTheme,
	TextField,
	InputAdornment,
	Box,
} from '@mui/material';

import { ApiAutomation, APIProperty, ApiTeam } from '@rentcheck/types';

import { Column, Spacer, Button } from 'components';
import { numberAndPluralizeWord } from 'utils/helpers';
import DialogTitle from 'screens/modal-flows-controller/create-inspection/common/inner-dialog-title';

import { SelectionCard, innerTransition } from '../common';
import selectionTypeData from './selection-type-data';
import SelectType from './select-type';
import SelectTeams from './select-teams';
import SelectProperties from './select-properties';
import Selected from './selected';
import PropertiesList from './properties-list';

interface Props {
	selectionType: Partial<ApiAutomation['selection_type']>;
	setSelectionType: React.Dispatch<
		React.SetStateAction<Partial<ApiAutomation['selection_type']>>
	>;
	properties: APIProperty[];
	setProperties: (properties: APIProperty[]) => void;
	apiTeams: ApiTeam[];
	teams: ApiTeam[];
	setTeams: (teams: ApiTeam[]) => void;
	setTemplate: React.Dispatch<
		React.SetStateAction<Partial<ApiAutomation['inspection_template']>>
	>;
	assignmentType: Partial<ApiAutomation['assignment_type']>;
	setAssignmentType: React.Dispatch<
		React.SetStateAction<Partial<ApiAutomation['assignment_type']>>
	>;
}

const SelectionType = ({
	selectionType,
	setSelectionType,
	setProperties,
	apiTeams,
	setTeams,
	setTemplate,
	teams,
	properties,
	assignmentType,
	setAssignmentType,
}: Props) => {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [modalOpen, setModalOpen] = useState(false);
	const [search, setSearch] = useState('');

	const [typeField, setTypeField] = useState<
		ApiAutomation['selection_type']['type']
	>(selectionType.type ?? 'all_units');
	const [listProperties, setListProperties] = useState<APIProperty[]>([]);
	const [listTeams, setListTeams] = useState<ApiTeam[]>(
		apiTeams.filter((t) => (selectionType.ids ?? []).includes(t.id))
	);

	const [teamIdFilters, setTeamIdFilters] = useState<string[]>([]);

	const handleOpen = () => {
		setTypeField(selectionType.type ?? 'all_units');
		setModalOpen(true);
	};

	const handleClose = () => {
		setModalOpen(false);
	};

	const handleTypeField = (event: SelectChangeEvent) => {
		const type = event.target.value as ApiAutomation['selection_type']['type'];
		setTypeField(type);

		if (type === 'all_units') {
			if (type !== selectionType.type) {
				setTemplate({});

				if (assignmentType.type === 'teammates') {
					setAssignmentType({
						type: 'teammates',
						teammate_emails: {},
					});
				}
			}

			setSelectionType({ type });
			handleClose();
		}
	};

	const handleAddTeamsOrUnits = () => {
		if (typeField !== selectionType.type) {
			setTemplate({});
		}

		if (assignmentType.type === 'teammates') {
			setAssignmentType({
				type: 'teammates',
				teammate_emails: {},
			});
		}

		if (typeField === 'specific_units') {
			setSelectionType({
				type: typeField,
				ids: listProperties.map((p) => p.id),
			});
			setProperties(listProperties);
		}

		if (typeField === 'specific_teams') {
			setSelectionType({
				type: typeField,
				ids: listTeams.map((t) => t.id),
			});
			setTeams(listTeams);
		}

		handleClose();
	};

	const { addTeamsOrUnitsText, disableButton } = useMemo(() => {
		if (typeField === 'all_units') {
			return { addTeamsOrUnitsText: 'Save', disableButton: false };
		}

		const typeIsTeams = typeField === 'specific_teams';
		const items = typeIsTeams ? listTeams : listProperties;
		const word = _.capitalize(typeIsTeams ? 'team' : 'unit');

		return {
			addTeamsOrUnitsText: items.length
				? `Add ${numberAndPluralizeWord(items.length, word)}`
				: `Add ${word}s`,
			disableButton: !items.length,
		};
	}, [typeField, listTeams, listProperties]);

	if (!selectionType.type) return null;

	const { title, description, caption } = selectionTypeData[selectionType.type];

	return (
		<Column>
			<Typography variant="overline">Property Selection</Typography>
			<Spacer height={2} />

			<SelectionCard
				title={title}
				description={description}
				caption={caption}
				onClick={handleOpen}>
				<Selected
					selectionType={selectionType}
					teams={teams}
					properties={properties}
				/>
			</SelectionCard>

			<Spacer height={5} />

			<Dialog
				open={modalOpen}
				onClose={handleClose}
				TransitionComponent={innerTransition}
				fullScreen={mobileScreen}>
				<DialogTitle title="Property Selection" onClose={handleClose} />

				<DialogContent
					style={{
						minHeight: typeField === 'all_units' ? '60vh' : '',
						display: 'flex',
						flexDirection: 'column',
					}}>
					<Box sx={{ flex: '0 0 auto', paddingBottom: 2 }}>
						<SelectType
							typeField={typeField}
							handleTypeField={handleTypeField}
						/>

						{typeField === 'specific_teams' && (
							<TextField
								style={{ paddingTop: 10 }}
								fullWidth
								placeholder="Search"
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<FontAwesomeIcon icon={regular('search')} />
										</InputAdornment>
									),
								}}
							/>
						)}

						{typeField === 'specific_units' && (
							<SelectProperties
								apiTeams={apiTeams}
								teamIdFilters={teamIdFilters}
								setTeamIdFilters={setTeamIdFilters}
								search={search}
								setSearch={setSearch}
							/>
						)}
					</Box>

					{typeField === 'specific_units' && (
						<PropertiesList
							value={listProperties}
							setValue={setListProperties}
							search={search}
							propertyType="unit"
							filters={{ teamId: teamIdFilters }}
						/>
					)}

					{typeField === 'specific_teams' && (
						<SelectTeams
							options={apiTeams}
							search={search}
							value={listTeams}
							setValue={setListTeams}
						/>
					)}
				</DialogContent>

				<DialogActions>
					<Button variant="text" color="secondary" onClick={handleClose}>
						Back
					</Button>

					<Button
						variant="contained"
						onClick={handleAddTeamsOrUnits}
						disabled={disableButton}>
						{addTeamsOrUnitsText}
					</Button>
				</DialogActions>
			</Dialog>
		</Column>
	);
};

export default SelectionType;
