import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';

import { SubscriptionsApi } from '@rentcheck/api-frontend';
import {
	BillingModalOption,
	CreateSubscriptionRequestParams,
} from '@rentcheck/types';
import { DialogTitle, Spacer } from 'components';
import { LoadingButton } from 'components/button';
import FormTextField from 'components/form-text-field';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';
import { BillingModalPlanVariant, BillingOptionContext } from '../../context';

interface Props {
	open: boolean;
	selectedPlan: BillingModalOption['plan'];
	selectedPlanVariant: BillingModalPlanVariant;
}

export default ({ open, selectedPlan, selectedPlanVariant }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const setBillingPlanAndVariant = useContext(BillingOptionContext);

	const [note, setNote] = useState<string>('');
	const [loading, setLoading] = useState(false);

	const plan_id =
		selectedPlanVariant === 'free-trial'
			? selectedPlan?.free_trial?.id
			: selectedPlan?.id;

	const variant = selectedPlanVariant === 'free-trial' ? 'Trial' : 'Plan';

	const handleSubmit = ({
		plan_id,
		note,
	}: CreateSubscriptionRequestParams['data']) => {
		setLoading(true);

		SubscriptionsApi.requestPlan({
			plan_id,
			note: note?.trim(),
		})
			.then(() =>
				dispatch(
					SnackbarActions.showSuccess(
						`Success! ${selectedPlanVariant === 'free-trial' ? 'Trial' : 'Upgrade'} request sent.`
					)
				)
			)
			.then(() => dispatch(ModalFlowActions.closeCurrentModal()))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	};

	const handleBack = () => {
		setBillingPlanAndVariant(undefined, undefined);
	};

	return (
		<Dialog open={open}>
			<DialogTitle
				icon={solid('sparkles')}
				title={`Request ${variant}: RentCheck ${selectedPlan.name}`}
				onClose={handleBack}
			/>

			<DialogContent>
				<Typography variant="subtitle1">
					We'll send your request to people on your team with permission to
					start trials and upgrade plans.
				</Typography>

				<Spacer height={4} />

				<FormTextField
					helperText="0-500 Characters"
					placeholder={'Add note'}
					inputProps={{ maxLength: 500 }}
					icon={regular('message-lines')}
					multiline
					value={note}
					onChange={(e) => setNote(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleBack}>
					Back
				</Button>
				<LoadingButton
					disabled={loading}
					loading={loading}
					onClick={() => handleSubmit({ plan_id, note })}>
					{`Request ${variant}`}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
